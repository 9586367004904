@charset "UTF-8";

@import "./utils/variable";
@import "./utils/retina-background";

@import "~slick-carousel/slick/slick.css";
@import "~react-datepicker/dist/react-datepicker.css";

// @import './utils/retina-sprites'

@import "./base/default";

@import "./layout/main";

@import "./module/main";

@import "./state/default";

@import "./state/farnet-page";

@import "./state/rate-plan";

@import "./state/exclusive";

@import "./theme/default";

@import "./state/product";
