.checkbox {
    position: relative;
    display: inline-block;
    margin: ($space * 4) 0;
    vertical-align: middle;

    &.is-agreement {
        .content {
            padding-left: 0;
            line-height: 30px;
            font-size: 0.875rem;

            &::before {
                margin-top: -3px; }

            &::before,
            &::after {
                position: relative;
                float: left;
                cursor: pointer; }

            &::after {
                position: absolute;
                top: 8px;
                margin-left: -43px; } } }

    input {
        position: absolute;
        width: 30px;
        height: 30px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        top: 0; }

    input + .content {
        font-size: 0.875rem;
        font-weight: $font-regular;
        padding-top: 5px;
        padding-left: $space * 5;
        position: relative;

        &::after,
        &::before {
            position: absolute;
            box-sizing: border-box; }

        &::after {
            content: "";
            font-family: "icomoon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: 1rem;
            display: block;
            width: 10px;
            height: 10px;
            color: $white;
            top: 8px;
            left: 8px;
            opacity: 0;
            transform: scale(0);
            transform-origin: 50%;
            transition: all 0.3s ease; }

        &::before {
            content: "";
            display: block;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            margin-bottom: 10px;
            background: transparent;
            border: 2px solid $lightgray100;
            transition: all 0.3s ease; } }

    input:checked + .content {
        &::before {
            background: $error;
            opacity: 1;
            border-color: $error; }

        &::after {
            color: $white;
            opacity: 1;
            transform: scale(1); } } }

.checkbox-buttons {
    margin: ($space * 2) 0;
    display: flex;
    flex-wrap: wrap;

    .checkbox {
        width: 100%;
        margin: 9px 0; }

    .checkbox input + .content {
        font-size: 16px; }

    @media screen and (min-width: 960px) {
        // margin-bottom: $space*10
        .checkbox {
            width: 25%; } } }
.is-merge-bill {
    > label {
        font-size: 24px;
        margin-bottom: 24px; }
    .checkbox-group {
        .checkbox.is-icon-box {
            .content {
                padding: 18px 18px 18px 54px;
                .text {
                    text-align: left; } } } }
    @media screen and (max-width: 960px) {
        > label {
            font-size: 22px; }
        .checkbox-group .checkbox.is-icon-box {
            width: 100%;
            margin-right: 0;
            height: 52px;
            .content {
                padding-left: 46px; } } } }

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;

    .checkbox {
        position: relative;
        width: calc(50% - 12px);
        margin: 0 12px 12px 0;
        background-color: #fff;

        input {
            position: absolute;
            opacity: 0; }

        input + .content {
            color: $darkgray50;
            padding-left: 0;
            border: solid 2px $lightgray100;
            transition: all 0.3s ease;
            font-weight: $font-medium;

            &::after,
            &::before {
                position: absolute; }

            &::after {
                top: -4px;
                bottom: 0;
                left: $space * 1.7;
                margin: auto; }

            &::before {
                top: 0;
                bottom: 0;
                margin: auto;
                left: 6px; }

            .text {
                line-height: 28px;
                text-align: center; } }

        input:checked + .content {
            color: $error;
            border-color: $error; }

        &.is-icon-box {
            text-align: center;

            .content {
                display: flex;
                align-items: center;
                align-content: center;
                line-height: 22px;
                height: 100%;
                width: 100%;
                padding: ($space * 3) ($space * 5.1) ($space * 3); } } }

    @media screen and (min-width: 960px) {
        .checkbox {
            width: calc(33.33333% - 12px);

            input + .content {
                padding-left: $space * 6;

                &::after {
                    top: $space * 2.5;
                    left: $space * 2.5;
                    margin: auto; }

                &::before {
                    top: $space * 1.5;
                    left: $space * 1.5; } }

            &.is-icon-box {
                .content {
                    display: block;
                    // padding: ($space * 2.75) $space

                    &::before {
                        bottom: auto; }

                    &::after {
                        left: 20px;
                        bottom: auto; } } } } } }

.is-ebu,
.is-help-center {
    .checkbox {
        input:checked + .content {
            &::before {
                border-color: $business50;
                background: $business50; } } }

    .checkbox-group .checkbox {
        input:checked + .content {
            color: $business50;
            border-color: $business50; } } }

.input-with-checkbox label.checkbox {
    margin-top: 0px;

    @media screen and (min-width: 600px) {
        margin-top: 55px; } }

@media screen and (max-width: 960px) {
    .checkbox-group {
        .checkbox {
            &.is-icon-box {
                .content {
                    padding: ($space * 3) $space ($space * 3) ($space * 5.1);

                    .text {
                        text-align: left; } } } } } }
