label.fui-switch {
    position: relative;
    cursor: pointer;

    input {
        opacity: 0;
        position: absolute;

        + div.content span {
            &.off {
                color: #EF3123; } }

        &:checked + div.content {
            &::after {
                left: 24px; }

            span.off {
                color: $black50; }

            span.on {
                color: #2F59C4; } }

        + .content {
            position: relative;
            padding: 0 28px;

            span {
                position: absolute;
                font-size: 14px;
                top: 0;
                bottom: 0;
                height: 20px;
                line-height: 20px;
                white-space: nowrap;
                margin: auto;
                transition: color 0.3s ease;

                &.off {
                    left: 0; }

                &.on {
                    right: 0; } }

            &::before {
                content: "";
                top: 0;
                bottom: 0;
                display: inline-block;
                width: 48px;
                height: 10px;
                margin: auto 6px;
                background: #484848;
                border-radius: 6px; }

            &::after {
                content: "";
                position: absolute;
                width: 24px;
                height: 24px;
                display: block;
                transition: all 0.3s ease;
                top: -100%;
                bottom: -100%;
                left: -24px;
                right: 0;
                margin: auto;
                background: #FFFFFF;
                box-shadow: 0 4px 12px 0 #{"rgba(61, 61, 61, 0.24)"};
                border-radius: 15px; } } } }

div.fui-header {
    background: none !important; }

header.fui-header {
    width: 100%;
    z-index: 39;
    background: #FFF;
    padding: 0;

    // &.is-ie
    //     opacity: 0
    //     pointer-events: none

    &.is-fixed {
        position: fixed;
        top: 0; }

    a,
    a:link,
    a:visited {
        color: $black50;

        &:hover,
        &:active {
            color: $accent; } }

    .fui-container {
        width: 100%;
        max-width: 1440px; }

    .fui-control-bar,
    .fui-navbar {
        position: relative;
        z-index: 2; }

    .fui-control-bar {
        .fui-container {
            display: flex;
            justify-content: space-between;
            line-height: 20px; } }

    .fui-sidebar {
        .menu .item {
            display: block; } }

    &::before {
        content: "";
        position: fixed;
        width: 100vw;
        height: 100vh;
        display: block;
        background: #{"rgba(0, 0, 0, 0.5)"};
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s 0.2s ease; }

    &.is-search-open,
    &.is-open {
        transform: translateY(0px) !important;
        z-index: 100;

        @media screen and (max-width: 960px) {
            height: 100%; }

        &::before {
            pointer-events: all;
            z-index: 29;
            opacity: 1; } }

    &.is-open {
        .fui-sidebar {
            left: 0vw;
            overflow-y: auto;
            overflow-x: hidden;
            z-index: 999; }

        .menu-trigger {
            border: 0;
            background: none;

            span {
                top: 0;
                bottom: 0;
                margin: auto; }

            span:nth-child(2) {
                width: 0; }

            span:first-child {
                transform: rotate(45deg); }

            span:last-child {
                transform: rotate(-45deg); } } }

    .right {
        a {
            font-size: 14px; } }

    button {
        border: 0;
        background: none; }

    .fui-control-bar {
        display: none;
        height: 40px;
        background-image: linear-gradient(180deg, #FFFFFF 0%, #FBFBFB 100%);
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: space-between;
        justify-items: space-between; }

    nav.fui-navbar {
        background: #FFF;
        box-shadow: none !important;
        border: 0 !important; }

    // box-shadow: 0 0 50px 0 rgba(0,0,0,.12)
    #brand,
    .brand {
        display: inline-block;
        width: 100px;
        height: 36px;
        text-indent: -9990px;
        overflow: hidden;
        background: url(#{$publicURL}/resources/common/images/mobile-logo.png) no-repeat center top;
        background-size: 94px 30px; }

    .fui-navbar-menu {
        padding: $space 0;
        display: flex;
        align-content: center;
        align-items: center;
        justify-items: space-between;
        justify-content: space-between;
        min-height: 50px;

        @media screen and (max-width: 600px) {
            #Search-0 {
                padding-right: 0; } }

        .navbar-center {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 125px;
            display: flex;
            align-items: center;
            margin: auto;
            justify-content: center; }

        .position-relative {
            position: relative; }

        .navbar-text {
            color: $gray100;
            font-weight: $font-bold; }

        .navbar-right {
            text-align: right;

            #search-trigger {
                padding-right: 0; } }

        .menu-trigger {
            position: relative;
            border: 0;
            padding: 0;
            background: none;
            width: 22px;
            height: 22px;
            margin-top: 5px;
            cursor: pointer;

            span {
                position: absolute;
                width: 20px;
                height: 2px;
                top: 0;
                bottom: 0;
                margin: auto;
                display: block;
                background: $black50;
                transition: all 0.3s ease;
                transform-origin: 50%;

                &:first-child {
                    bottom: 14px;
                    transform-origin: 0% 50%; }

                &:last-child {
                    top: 14px;
                    transform-origin: 0% 50%; } } }

        button.navbar-item {
            cursor: pointer; }

        .navbar-item {
            display: inline-block;
            vertical-align: middle;
            color: $black50;
            line-height: 0;
            padding: 0 15px;
            font-size: 1.2rem;
            font-weight: $font-bold;

            &::after {
                display: none; }

            &:last-child {
                padding-right: 0; }

            *[class*="icon-"] {
                font-size: 30px; }

            &[role="button"]:hover {
                color: $main;
                padding: 0; }

            .fui-dropdown button.fui-dropdown-item {
                padding: 0;

                span {
                    display: flex;
                    align-content: center;
                    align-items: center; } } } }

    .fui-menu-container {
        position: absolute;
        width: 100%;
        top: 40px;
        background: #E8ECF0;
        display: none; }

    &.is-help-center .fui-menu-container {
        top: 0; }

    &.is-5g-header.fui-header.is-open {
        .fui-menu-container {
            top: 0; } } }

.fui-header {
    width: 100%;
    z-index: 39;
    background: #FFF;
    padding: 0;

    &.is-fixed {
        position: fixed;
        top: 0; } }

.header-footer-page {
    height: calc( 100vh - 166px );
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        object-fit: contain; }

    &.office365 {
        height: calc( 100vh - 194px ); } }

header.fui-header {
    .fui-item[role='button']:hover {}

    .menu-action {
        .fui-item {
            cursor: pointer;

            &:hover {
                color: $accent; } } } }

.is-ebu header.fui-header {
    .menu-action .fui-item:hover {
        color: $business50; } }

header.fui-header.seednet {
    .seednet-logo {
        img {
            width: 110px; } }

    .navbar-center {
        width: initial;

        a:nth-last-child(1) {
            display: flex;
            margin-left: 6px;

            &::before {
                content: "";
                border-left: 1px solid $darkgray70;
                height: 30px;
                margin-top: 10px;
                padding-right: 16px; } } } }

.is-ebu header.fui-header a,
.is-ebu header.fui-header a:link,
.is-ebu header.fui-header a:visited {
    &:hover,
    &:active {
        opacity: 1;
        color: $business50; } }

.is-eservice header.fui-header,
.is-cbu header.fui-header {
    .fui-menu-container {
        .fui-menu-body .menu-content {
            .fui-card-container {
                .fui-cards.is-flat {} } } } }

header.fui-header.ebu-login {
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);
    position: relative;

    .fui-navbar-menu {
        padding: 12px 0;
        height: 74px; } }

.sidebar-open header.fui-header {
    position: fixed; }

@media screen and (min-width: 960px) {
    header.fui-header {
        padding: 0;

        &.is-open {
            .fui-menu-container {
                height: 730px;
                top: 40px;
                display: block;
                z-index: 299; }

            .menu-header::before {
                height: 670px; }

            .fui-menu-header + .fui-menu-body {
                overflow-y: auto;
                height: calc(100vh - 100px);
                max-height: 670px; }

            &.is-help-center .fui-menu-container {
                height: 640px; }

            &.is-help-center .fui-menu-container .fui-menu-header + .fui-menu-body {
                height: calc(100vh - 60px);
                max-height: 580px; } }

        &.is-help-center .fui-menu-container .menu-header,
        &.is-help-center .fui-menu-container .menu-header::before,
        &.is-help-center .fui-menu-container .menu-extra {
            height: 580px; }

        &.is-help-center.is-open {
            .fui-menu-container {
                top: 0; } }

        .fui-container {
            width: 100%; }

        .fui-control-bar {
            display: flex;
            z-index: 199; }

        .fui-navbar-menu {
            height: 60px;

            .navbar-right #search-trigger {
                padding-right: 15px; } }

        #brand,
        .brand {
            width: 150px;

            // height: 50px
            height: 46px;
            background: url(#{$publicURL}/resources/common/images/fetnet-logo.png) no-repeat center;
            background-size: 125px 40px; }

        .fui-dropdown {
            height: auto;

            .menu-wrapper {
                .fui-item {
                    justify-content: center; }

                .fui-item:hover {
                    color: $accent !important;
                    background: $lightgray50 !important; } } }

        .fui-menu-container {
            // position: relative
            display: none;
            height: 0px;
            background: #FFF;
            overflow: hidden;
            transition: all 0.3s ease;

            .brand {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto; }

            .fui-menu-header {
                position: relative;
                height: 60px;
                padding: 0;
                background: #FFF;

                > .fui-container {
                    position: relative;
                    height: 60px; }

                + .fui-container {
                    overflow-y: auto; }

                .fui-navbar-menu {
                    display: flex !important; }

                .close {
                    position: absolute;
                    font-size: 24px;
                    top: 4px;
                    bottom: 0;
                    padding: 0;
                    width: 24px;
                    height: 32px;
                    margin: auto;
                    border: 0;
                    cursor: pointer;
                    background: none; } }

            .menu-header {
                display: flex;
                flex-direction: column;
                height: 670px;
                background: $lightgray50;

                &::before {
                    content: "";
                    position: absolute;
                    width: 50vw;
                    display: block;
                    background: $lightgray50;
                    right: calc(100% - 16px); }

                .menu-action {
                    padding-bottom: 180px;

                    .fui-item {
                        padding-left: 30px;

                        img {
                            width: auto;
                            height: 40px;
                            margin-left: -30px;
                            margin-right: $space * 1.5; } } }

                .menu-content {
                    flex: 1 1 0;
                    flex-grow: 1;
                    padding-top: $space * 3;

                    .fui-item[role='button']:hover,
                    .fui-item[role='button']:active {
                        color: $accent;
                        opacity: 1; }

                    .fui-item.is-active {
                        background: #ffffff; } } }

            .menu-body {
                display: flex;
                flex-direction: column;
                background: #FFF;

                h6 {
                    font-size: 1.125rem;
                    font-weight: $font-medium;
                    margin: $space 0 0; }

                .menu-action {
                    .action {
                        background: $lightgray50;
                        padding: ($space * 1.5) ($space * 5); } }

                .menu-content {
                    flex: 1 1 0;
                    flex-grow: 1;
                    padding: ($space * 3) 30px 0;
                    padding-top: $space * 3;

                    .fui-item .content {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis; }

                    .fui-item.more {
                        display: inline-flex;
                        width: auto;
                        font-weight: $font-medium;

                        i[class*='icon-'] {
                            font-size: 24px; } } }

                .fui-card-container {
                    > h6 {
                        margin-top: ($space * 2) !important; }

                    .fui-cards.is-flat {
                        margin: 12px -6px;

                        .fui-card {
                            flex: 1;
                            flex-grow: 1;

                            .fui-card-caption .fui-card-content {
                                h4.fui-card-title {
                                    margin-bottom: 0; }

                                p.fui-card-description {
                                    margin-top: 6px; } } } } } }

            .menu-extra {
                position: absolute;
                width: 240px;
                height: 670px;
                overflow: hidden;
                background: #E8ECF0;
                display: none;
                right: 0;
                top: 0px;

                .menu-extra-image {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    z-index: 0; }

                .menu-extra-caption {
                    position: relative;
                    z-index: 1;
                    padding: ($space * 7) ($space * 3);

                    h3 {
                        margin: 0 0 $space; }

                    p {
                        margin: 0 0 ($space * 3);
                        font-size: 1.125rem; } } } } } }

@media screen and (min-width: 1200px) {
    header.fui-header {
        .fui-menu-container {
            .menu-extra {
                display: block; } } } }

@media screen and (max-width: 1279px) and (min-width: 1200px) {
    header.fui-header {
        .fui-menu-container {
            .menu-body {
                padding-right: 220px; } } } }

@media screen and (max-width: 1280px) and (min-width: 1300px) {
    header.fui-header {
        .fui-menu-container {
            .menu-body {
                padding-right: 160px; } } } }

@media screen and (min-width: 1920px) {
    header.fui-header {
        .fui-menu-container {
            .menu-body {
                padding-right: 210px; } } } }
