section.card-roaming-single,
.card-roaming-multi {
    .fui-card {
        position: relative;
        height: 296px;
        background-color: $white;

        .fui-card-image {
            flex: 1 1 394px;
            padding-bottom: 0;
            width: auto;

            img {
                width: auto;
                transform: scale(1.2); } }

        .fui-card-caption {
            flex: 1 1 auto;
            padding: 30px;

            .fui-card-meta {
                margin-bottom: 12px; }

            span {
                // margin: 0 12px 0 6px
                margin: 0 12px 0 0; }

            del {
                // margin: 0 12px 0 6px
                margin: 0 12px 0 0;
                color: $darkgray50; } }

        .fui-card-action {
            display: flex;
            flex-direction: column;
            flex: 1 1 250px;
            padding: 0 30px;
            margin: 30px 0;
            border-left: 2px solid $gray30;
            justify-content: center;

            h1 {
                margin: 0 0 6px; }

            small {
                margin-right: 12px; } } }

    @media screen and (min-width: 641px) and (max-width: 768px) {
        .fui-card .fui-card-image img {
            transform: scale(1);
            position: absolute;
            left: -100%;
            right: -100%;
            margin: auto; } }

    @media screen and (max-width: 640px) {
        .fui-card {
            display: block;
            height: auto;

            .fui-card-image {
                height: auto;
                display: block;
                width: 100%;

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                    left: 0; } }

            .fui-card-action {
                min-height: auto; }

            .fui-card-caption {
                padding: 12px 24px; }

            .fui-card-action {
                padding: 0 24px 24px;
                margin: 0;
                border: 0; } } } }

section.card-roaming-multi,
.card-roaming-multi {
    padding: 0 15px;
    display: block;
    overflow: visible;

    .fui-card {
        .fui-card-image {
            min-width: 394px;
            flex: 1;

            img {
                transform: scale(1); } }

        .fui-card-caption {
            white-space: normal;

            .title,
            .desc,
            .target {
                margin-bottom: 12px; }

            .country {
                margin-bottom: 2rem; }

            .target,
            .country {
                img {
                    margin-right: 5px; } }

            .underline {
                text-decoration: underline; } }

        .fui-card-action {
            min-width: 250px; }

        .fui-tooltip-container {
            margin-left: 5px; } } }

@media screen and (max-width: 1280px) {
    section.card-roaming-multi,
    .card-roaming-multi {
        .fui-card {
            height: auto; } } }

@media screen and (max-width: 960px) {
    .fui-container {
        section.card-roaming-multi,
        .card-roaming-multi {
            padding-bottom: 0 !important; } }

    section.card-roaming-multi,
    .card-roaming-multi {
        padding-bottom: 0 !important;

        .fui-card {
            height: auto;
            margin: 0 0 12px 0;
            display: block !important;

            .fui-card-image {
                min-width: 100%; }

            .fui-card-caption {
                padding: 24px; }

            .fui-card-action {
                position: relative;
                min-width: 100%;

                h1 {
                    position: absolute;
                    right: 24px;
                    margin-top: -65px; } } } } }

// ie10, ie11 hack
@media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .card-roaming-multi,
    section.card-roaming-multi {
        .fui-card {
            .fui-card-image,
            .fui-card-caption,
            .fui-card-action {
                display: block;
                float: left; } } } }

// ie edge hack
// @supports (-ms-ime-align: auto)
