.has-more-tab {
    position: relative;
    display: flex;
    z-index: 13;

    .tab-wrapper {
        display: flex;
        width: 100%;
        white-space: nowrap;

        .active {
            color: $main;
            border-bottom: 6px solid $accent; } }

    .tab {
        flex: 1 1 auto;
        padding: 18px 4px 12px 4px;
        font-weight: 500;
        display: inline-block;
        cursor: pointer;
        border-bottom: 6px solid $gray50;
        text-align: center;
        transition: all 0.5s;

        &:hover {
            color: $main; } }

    .fui-dropdown {
        flex: 1 1 auto;
        display: flex;
        height: initial;

        &.active button {
            border-bottom: 6px solid $accent !important; }

        button {
            color: #000 !important;
            font-weight: 500;
            width: 100%;
            white-space: nowrap;
            border-bottom: 6px solid $gray50;
            padding-top: 6px;
            margin-bottom: 0;

            &:hover {
                transition: all 0.5s;

                span, i {
                    color: $main; } }

            a {
                text-align-last: left; } }

        .fui-menu {
            top: 56px !important;
            z-index: 1;

            .fui-item {
                background-color: #fff;
                text-align: left;
                padding: 8px 18px; } }

        .fui-dropdown-item {
            display: block; }

        .menu-wrapper {
            width: 100%;
            margin: 5px 0 0 0;
            right: 0; } }

    .fui-dropdown.active {
        position: relative;

        .fui-dropdown-item {
            border-bottom: 0;
            flex: 1 1 auto;

            span, i {
                color: $main; } }

        .menu-wrapper .active {
            border: 0; } } }

@media screen and (max-width: 960px) {
    .has-more-tab {
        padding-right: -15px;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 12px;

        * {
            vertical-align: top; }

        .tab-wrapper {
            flex: 1 1 auto;
            display: blcok;
            overflow-x: auto;
            overflow-y: hidden;

            .tab {
                padding: ($space * 1.5) ($space * 1.5); } }

        .fui-dropdown {
            width: 100px;
            white-space: nowrap;
            display: inline-block;
            position: relative;
            top: 1px;
            @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
                top: 0; }
            button {
                padding: 10px 16px; }

            .fui-menu {
                width: 100%;
                top: 40px;
                position: absolute; }

            .menu-wrapper {
                width: calc(100% - 30px );
                margin: 5px 15px 0 15px; } }

        .fui-dropdown.active {
            position: static;

            &::after {
                display: none; }

            button {
                transition: all 0s;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -6px;
                    z-index: 10;
                    height: 6px;
                    left: 0;
                    width: 100%;
                    background-color: $accent; } } } } }
