.life-circle-head {
  background-color: $lightgray70;
  margin-top: -94px;
  &--wrapper {
    margin: 16px auto 0;
    padding: 62px 15px 30px;
    max-width: 862px;
    min-height: 574px; }
  h1 {
    margin-bottom: 24px;
    font-size: 56px; }
  .body {
    line-height: 1.78;
    letter-spacing: 0.2px;
    margin-bottom: 48px; }
  .hashtags {
    display: inline-block;
    .tag {
      display: inline-flex;
      line-height: 1.5;
      letter-spacing: 0.2px;
      font-size: 18px;
      margin-right: 12px;
      margin-bottom: 10px;
      position: relative;
      font-weight: 500;
      cursor: pointer;
      .tag-head {
        width: 56px;
        height: 60px; }
      .tag-end {
        width: 26px;
        height: 50px; }
      .tag-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 24px; }
      .tag-body {
        padding: 12px;
        height: 50px;
        background-color: $white; } } }
  @media screen and (max-width: 960px) {
    &--wrapper {
      min-height: auto; }
    .hashtags {
      .tag {
        font-size: 16px;
        margin-right: 0;
        margin-bottom: 6px;
        .tag-head {
          width: 40px;
          height: 43px; }
        .tag-end {
          width: 19px;
          height: 36px; }
        .tag-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 16px; }
        .tag-body {
          padding: 6px;
          height: 36px;
          background-color: $white; } } }
    h1 {
      font-size: 42px;
      margin-top: 50px; }
    .body {
      margin-bottom: 30px;
      font-size: 16px; } } }
.fui-nav-anchor.is-fixed ~ .life-circle-head {
  padding-top: 45px; }
