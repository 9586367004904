.fui-card-image .fui-card-content-box {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text {
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        font-size: 24px; } }
