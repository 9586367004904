.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
        width: 100%; }

    input[disabled] {
        background: #f1f3f5; } }

.react-datepicker-popper,
.react-datepicker__month-container {
    width: 100%;
    max-width: 492px; }

.react-datepicker {
    width: 100%;
    padding: 30px 0;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);

    .react-datepicker__navigation {
        top: 30px;
        width: 25px;
        height: 25px;
        border: 0 !important;

        &::after {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            transform: rotate(-45deg); }

        &.react-datepicker__navigation--previous::after {
            border-left: 2px solid $black50;
            border-top: 2px solid $black50; }

        &.react-datepicker__navigation--next::after {
            border-right: 2px solid $black50;
            border-bottom: 2px solid $black50; } }

    .react-datepicker__triangle {
        left: auto;
        right: 16px;
        margin: 0;
        border: 0;
        border-bottom-color: $white !important;

        &::before {
            border-bottom-color: $white !important; } }

    .react-datepicker__day-names {
        display: flex;

        .react-datepicker__day-name {
            flex: 1;
            flex-grow: 1;
            font-size: 18px;
            font-weight: $font-medium; } }

    .react-datepicker__header {
        background: none;
        padding: 0;
        border: 0;

        .react-datepicker__current-month {
            font-size: 18px;
            font-weight: $font-medium;
            margin-bottom: $space * 2; } }

    .react-datepicker__month {
        .react-datepicker__week {
            display: flex;

            .react-datepicker__day {
                flex: 1;
                flex-grow: 1;
                font-size: 18px;
                padding: 6px 0;
                font-weight: $font-medium;

                &:hover {
                    border-radius: 0; } }

            .react-datepicker__day--selected {
                background: $main;
                border-radius: 0; } } } }
