section.device-plan-table {
    .device-table-dropdown {
        display: flex;
        align-items: center;

        h2 {
            margin-bottom: 8px; }

        h3 {
            display: inline-block;
            margin: 0 42px 0 0;
            min-width: 100px; }

        .fui-dropdown {
            max-width: 302px;
            margin-left: 0;
            display: inline-block; } }

    .fui-plan-table .fui-plan-table-body .slick-slider .fui-plan-table-segment .fui-plan-table-item {
        .diff {
            justify-content: space-around;
            position: relative;

            .diff-item {
                max-width: 50%;
                padding: 0 6px;

                &::after {
                    content: "";
                    height: 100%;
                    border-right: 1px solid $lightgray100;
                    top: 0;
                    width: 2px;
                    position: absolute;
                    left: 50%; } } }

        .fui-tooltip-container {
            margin-left: 6px; } }

    .is-promotion-device {
        .fui-plan-table-label {
            line-height: 1.75 !important;
            align-items: center; }

        .slick-list {
            overflow: hidden; }

        .slick-initialized .slick-slide {
            height: auto; } }

    @media screen and (max-width: 960px) {
        .slick-list {
 }            // height: 450px

        .is-promotion-device .slick-list {
            overflow: visible; } }

    @media screen and (max-width: 640px) {
        .is-promotion-device .slick-list {
            overflow: visible; }

        h2 {
            margin-bottom: 0; }

        .device-table-dropdown {
            display: inline-block;
            width: 100%;

            h3 {
                margin: 0 18px 0 0;
                max-width: calc( 100% - 194px ); }

            .fui-dropdown {
                float: right;
                max-width: 176px; } } } }
