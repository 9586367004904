section.section-card-2 {
  .fui-tab-pane {
    padding-top: 30px;
    flex-wrap: wrap;
    display: none;
    &.is-active {
      display: flex; } }
  .fui-card {
    width: calc(33.33333% - 8px);
    display: inline-flex;
    margin-right: 12px;
    margin-bottom: 12px;
    .fui-card-action {
      cursor: initial; }
    .body {
      min-height: 82px;
      margin-bottom: 18px; }
    h4 {
      margin-bottom: 6px; }
    small {
      margin-bottom: 6px;
      display: block; }
    &:nth-of-type(3n) {
      margin-right: 0; } }
  @media screen and ( max-width: 960px ) {
    .fui-tab-pane {
      padding-top: 12px;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      width: 100%;
      white-space: nowrap;
      z-index: 0;
      &.is-active {
        display: block!important; }
      .fui-card-action {
        height: 100%;
        padding-bottom: 6px; }
      .fui-card {
        height: 262px;
        width: 330px;
        display: inline-block;
        h4 {
          margin-bottom: 12px; }
        .body {
          font-size: 16px; } } } } }
