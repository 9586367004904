.social-media {
    margin: 54px 0 10px;
    &.is-dark {
        .d-inline-block, i {
            color: $white; } }

    &.is-light {
        .d-inline-block, i {
            color: $black50; } }
    i {
        color: $black50;
        font-size: 36px;
        margin-right: 10px; } }
