section.recommend {
  .rate-card {
    display: flex;
    border: 2px solid $lightgray100;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 35px 0 30px;
    position: relative;
    margin-bottom: 30px;
    img {
      width: 160px; }
    .tag {
      position: absolute;
      left: 0;
      top: 0;
      color: $white;
      font-size: 14px;
      padding: 4px 12px;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-right: 28px solid transparent;
        position: absolute;
        right: -28px;
        bottom: 0; }
      &--red {
        background-color: $coral100;
        &::after {
          border-top: 28px solid $coral100;
          top: 0px; } } } } }
@media screen and (max-width: 960px) {
  section.recommend {
    padding-top: 30px;
    .rate-card {
      margin-bottom: 0;
      img {
        width: 100px; } } } }
