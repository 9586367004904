section.card-collapse-1 {
  .collapse-trigger {
    background-color: $lightgray50;
    height: 52px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .collapse-wrapper {
    display: flex;
    line-height: 32px;
    .head-img {
      width: 216px;
      margin-right: 40px;
      img {
        width: 100%; } }
    .collapse-content {
      width: calc( 100% - 216px );
      h5 {
        margin-top: 0;
        margin-bottom: 44px;
        font-weight: 500; } }
    .with-comma {
      position: relative;
      display: inline-block;
      padding: 24px 52px 0;
      margin-bottom: 56px;
      font-weight: 500;
      &:before {
          position: absolute;
          content: "";
          width: 40px;
          height: 32px;
          background: url("/resources/cbu/life-circle-drama/images/quotation-gray.svg") no-repeat;
          top: 0;
          left: 0;
          transform: rotate(180deg); } } }

  .fui-collapse.paper {
    background: $white url(#{$publicURL}/resources/common/images/promotion-watch-triangle-02@2x.png) no-repeat right bottom;
    background-size: auto 168px; }

  @media screen and ( max-width: 960px ) {
    .fui-collapse.paper {
      padding-bottom: 60px; }
    .collapse-wrapper {
      .head-img {
        display: none; }
      .collapse-content {
        width: 100%;
        h5 {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          img {
            width: 54px;
            margin-right: 12px; } }
        .body {
          font-size: 16px; }
        .with-comma {
          padding: 52px 0 0 0;
          margin-bottom: 30px;
          font-size: 18px; } } } } }
