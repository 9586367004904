section.section-product-info {
  .price {
    font-size: 24px;
    margin-bottom: 22px;
    del {
      color: $gray100; } }
  h2 {
    margin-bottom: 16px; }
  h6 {
    font-size: 18px;
    img {
      width: 32px;
      margin-right: 6px; } }
  .info-block {
    margin-top: 32px;
    .list {
      margin-bottom: 12px;
      &:nth-last-child(1) {
        margin-bottom: 0; }
      .list-tag {
        background-color: #95a3a4;
        color: $white;
        display: inline-block;
        padding: 6px 8px;
        font-size: 14px;
        margin-right: 20px;
        position: relative;
        font-weight: 500;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: -12px;
          width: 0;
          height: 0;
          border-top: 16px solid transparent;
          border-left: 12px solid #95a3a4;
          border-bottom: 16px solid transparent; } }
      small {
        display: inline-block;
        color: #5f7b80;
        font-weight: 400; } } } }
@media screen and ( max-width: 960px ) {
  section.section-product-info.fui-product-section {
    h2 {
      margin-bottom: 18px; }
    h6 {
      font-size: 14px; }
    .price {
      margin-bottom: 32px; }
    .slick-slider.fui-product-slider ul.slick-dots, section.fui-product-section .slick-slider.fui-product-slider ul.slick-dots {
      display: block!important; } } }
