.is-ebu footer.fui-footer,
.is-help-center footer.fui-footer,
footer.fui-footer {
    position: relative;
    font-size: 0.98rem;
    color: white;
    padding: 30px 0 ($space * 3);
    background: $footer-bg;
    z-index: 1;

    a, a:link, a:visited {
        color: $white;

        &:hover {
            opacity: 0.6; } }

    .fui-section-promo {
        margin-bottom: 30px; }

    .links {
        display: inline-block;
        margin-top: -14px;

        a {
            color: #fff;
            padding-right: 6px;
            border-right: 1px solid #fff;
            margin-right: 6px;
            height: 16px;
            line-height: 16px;
            display: inline-block;
            vertical-align: baseline; }

        .text-sm {
            display: flex;
            margin-top: 8px; }

        small {
            line-height: 16px; } }

    a.fui-item:link,
    a.fui-item[role="button"],
    .fui-item {
        font-weight: 300;
        color: $white;

        &:hover,
        &:focus {
            color: $white;
            opacity: 0.6; } }

    .fui-dropdown {
        display: table !important;

        // margin-top: $space*1.5
        margin-top: -6px;

        .fui-menu {
            z-index: 99; }

        .fui-menu .fui-item {
            color: $black50; } }

    .fui-collapse {
        margin: 0;

        .collapse-header {
            margin: 0;
            font-size: 1rem;
            line-height: 1.75;
            font-weight: $font-light;
            padding: 6px 0;

            &::after,
            &::before {
                color: $white; } }

        .mobile-collapse {
            padding-top: 0;

            .fui-item {
                font-size: 0.875rem;
                font-weight: 300;
                padding: 3px 0;
                line-height: 1.5rem; } } }

    p.text {
        line-height: 28px;

        * {
            vertical-align: middle; }

        i[class*='icon-'] {
            font-size: 24px; } }

    .text-gray {
        color: $gray100; }

    .footer-menu-title {
        color: $gray100;
        display: block;
        padding: $space 0; }

    .footer-menu-item {
        font-size: 14px;
        display: block;
        color: white;
        padding: ($space * 0.8) 0; }

    .phone-promo {
        font-size: 18px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 0;

        ~ p {
            margin-top: 0; }

        ~ a:link,
        ~ a:visited {
            color: $white;

            * {
                vertical-align: middle; }

            i {
                font-size: 24px; } }

        em, i {
            font-style: normal;
            font-size: 24px; } }

    .contact-info {
        line-height: 1.5;

        + a.MuiLink-root {
            color: white;
            margin-top: $space * 5; } }

    .MuiButton-root {
        color: #FFF; }

    .copyright {
        margin-top: $space * 4.5;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;

        img {
            width: 93px;

            // height: auto
            margin-bottom: $space * 1.5; } }

    .social-links {
        margin-top: $space * 2;
        margin-bottom: 10px;

        .socail-item {
            display: inline-flex;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            color: $white;
            font-size: 1.25rem;
            text-align: center;
            margin-right: $space * 3;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center;
            vertical-align: middle; } }

    .MuiGrid-root {
        > span {
            margin-bottom: 12px; } } }

footer.cooperation-footer {
    .cooperate-logo {
        padding: 0 60px;
        position: relative;
        display: flex;
        text-align: center;

        &::before {
            content: "";
            border-left: 1px solid $white;
            height: 44px;
            width: 1px;
            left: 0;
            top: -18px;
            position: absolute;
            order: 2; }

        // img
        //     width: 100%
        //     margin: 0
        .img-wrapper {
            margin-right: 18px;
            margin-bottom: 0;

            // width: 96px
            height: 44px;
            vertical-align: middle;

            img {
                width: auto;
                height: 100%;
                margin-right: 0; } } }

    @media screen and (max-width: 1280px) {
        .cooperate-logo {
            .img-wrapper {
                height: 28px; } } }

    @media screen and (max-width: 960px) {
        margin: 0;

        .cooperate-logo {
            order: 1 !important;
            display: block !important;
            padding: 0 30px;

            .img-wrapper {
                width: 50%;
                display: inline-block !important;
                padding: 0 0 30px !important;
                margin-right: 0 !important;
                margin-bottom: 30px;

                &:nth-of-type(even) {
                    padding-left: 30px !important; }

                img {
                    max-width: 144px;
                    height: auto;
                    width: 100%; } }

            .border-bottom {
                border-bottom: 1px solid $white;
                width: 216px;
                margin: 30px auto 24px; }

            &::before {
                display: none; } } } }

footer.blank-footer {
    padding-top: 24px !important;

    .links {
        text-align: left; }

    .MuiGrid-root {
        align-items: center;
        margin-top: 18px;
        order: 2; }

    .copyright {
        margin-top: 0 !important;

        .MuiGrid-item > .fui-dropdown {
            margin: 0 auto;
            width: 100%; }

        img {
            margin-top: -18px; } } }

footer.fui-footer.mrtg {
    img {
        margin-top: -14px; } }

footer.fui-footer.office365 {
    height: 120px;
    padding: 21px 0;

    img {
        margin-top: -52px; }

    .copyright {
        margin-top: 16px; }

    .links {
        span {
            margin-top: 2px; } } }

footer.fui-footer.ebu-login {
    height: 92px;
    padding: 21px 0;

    .copyright {
        margin-top: 16px; }

    .links {
        span {
            margin-top: 2px; } } }

footer.fui-footer.eng-footer {
    padding: 32px 0 0 0 !important;

    .footer-fetnet {
        margin-top: 12px; }

    .footer-lang {
        margin-top: 0; }

    img {
        margin-top: -20px; }

    .links {
        span.text-sm {
            margin-top: 0; } } }

@media screen and (min-width: 960px) {
    .is-ebu footer.fui-footer,
    .is-help-center footer.fui-footer,
    footer.fui-footer {
        padding: 30px 0 ($space * 3);

        .fui-section-promo {
            margin-bottom: $space * 5; }

        .fui-collapse {
            margin: 0;

            .collapse-header {
                font-size: 0.875rem; } }

        .copyright {
            vertical-align: bottom;

            img {
                margin-right: $space * 3;
                vertical-align: middle; } }

        .social-links {
            margin-top: $space * 6.5; }

        .phone-promo {
            font-size: 14px;
            margin-bottom: 0;

            em {
                font-size: 0.875rem !important; } }

        .collapse-header {
            color: $gray100;
            margin-bottom: 0; } } }

@media screen and (max-width: 960px) {
    .is-en .copyright .MuiGrid-item > .fui-dropdown {
        margin-bottom: 0 !important; }

    .is-en .footer-lang .fui-dropdown {
        .fui-dropdown-item {
            margin-top: 24px;
            margin-left: 0; } }

    .is-ebu footer.fui-footer,
    .is-help-center footer.fui-footer,
    footer.fui-footer {
        .copyright {
            .MuiGrid-item {
                &:nth-child(1) {
                    order: 2; }

                > * {
                    display: block;
                    text-align: center; }

                > img {
                    margin-left: auto;
                    margin-right: auto; }

                > .fui-dropdown {
                    margin-bottom: 38px;
                    width: 100%; } } }

        .phone-promo ~ p {
            font-size: 1rem; } }

    footer.blank-footer.fui-footer {
        .footer-fetnet {
            .d-flex {
                flex-direction: column; } }

        .links {
            width: 100%;
            margin-top: 0;
            text-align: center; }

        .copyright {
            img {
                margin-top: 0; }

            span.text-sm {
                margin-bottom: 12px; } }

        span {
            justify-content: center; } }

    .footer-lang {
        margin-top: 0 !important;
        order: 1 !important;

        .fui-dropdown {
            float: left;

            .fui-dropdown-item {
                // margin: 18px 0 0 16px
                margin: 0 auto; } } }

    .footer-fetnet {
        display: flex;
        flex-direction: column;
        order: 1;
        margin-top: 0 !important; }

    footer.fui-footer.eng-footer {
        padding: 16px 0 0 0 !important;
        height: initial;

        .footer-lang {
            text-align: left; }

        .copyright .MuiGrid-item {
            img {
                margin: 30px auto 20px !important; } }

        .links {
            margin-top: 0;

            span.text-sm {
                margin-top: 18px;
                padding-bottom: 30px; } } } }

@media screen and (max-width: 768px) {
    footer.fui-footer .copyright .MuiGrid-item {
        > img {
            margin: 0 auto 12px; }

        .fui-dropdown {
            width: 100%; } }

    footer.blank-footer {
        height: auto;

        .MuiGrid-root {
            order: 2;
            margin-top: $space * 3; }

        .links,
        footer.fui-footer .links {
            margin-top: 0;
            margin-bottom: $space * 1.5; }

        .footer-lang {
            .fui-dropdown {
                float: none; } } } }

@media screen and (min-width: 640px) and (max-width: 768px) {
    .is-ebu footer.fui-footer,
    footer.fui-footer {
        .copyright .MuiGrid-item > .fui-dropdown {
            margin-right: 60px; } } }

@media screen and (max-width: 767px) {
    .is-ebu footer.blank-footer,
    footer.fui-footer {
        .copyright .MuiGrid-item {
            > * {
                text-align: center; }

            > .fui-dropdown {
 } } } }                // margin: 0 auto !important
