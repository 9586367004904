section.multi-radio-card {
  .horizontal-cards .slick-list .slick-slide {
    width: 100%; }
  .fui-card {
    background-color: $white;
    padding: 60px 20px 36px;
    .wrapper {
      max-width: 414px;
      text-align: center;
      margin: 0 auto;
      img {
        max-width: 132px;
        margin: 0 auto 30px; }
      .radio-button {
        width: 100%;
        margin-right: 0;
        justify-content: space-between;
        align-items: baseline; }
      .form-group {
        max-width: 326px;
        margin: 0 auto;
        p {
          font-weight: 400;
          margin-left: 6px;
          margin-right: 12px;
          font-size: 16px;
          text-align: left; }
        .is-text-error {
          font-size: 16px; } }
      .page-action {
        margin-top: 12px; } } }
  @media screen and ( max-width: 960px ) {
    .fui-card {
      padding-top: 48px;
      .wrapper {
        h2 {
          font-size: 24px; }
        img {
          max-width: 90px;
          margin-bottom: 18px; }
        .page-action {
          a.fui-button {
            width: 140px; } } } } }
  @media screen and ( max-width: 600px ) {
    .horizontal-cards .slick-list .slick-slide {
      width: 290px; } } }
