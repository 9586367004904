.emma-service .emma-customize {
    display: block;
    font-weight: 500;
    height: 46px;
    line-height: 20px;
    padding: 3px 0;
    padding-left: 16px; }

@media screen and (min-width: 960px) {
    .emma-service .emma-customize {
        height: 54px;
        line-height: 24px;
        padding: 3px 0;
        padding-left: 18px; } }
