.fui-cbu-promotion {
    position: relative;
    color: $white;
    height: 98px;
    padding: 0;
    overflow: hidden;
    background-image: linear-gradient(269deg, $main 100%, #ff644d -6%);
    transition: height 0.3s ease;

    > a {
        display: block; }

    .fui-container {
        position: relative;
        height: 269px;
        padding: 20px 30px;

        .content {
            text-align: center;
            color: $white;

            * {
                width: 100%; }

            h4 {
                margin: 0;
                line-height: 2rem; }

            p {
                margin: 0;
                line-height: 28px; } }

        .action .fui-button {
            width: 100%;
            margin: 0; }

        img {
            position: absolute;
            width: auto;
            height: 120px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto; } }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(#{$publicURL}/resources/cbu/cbu-index/patternd-arkgray-50-banner-sm.png) left top no-repeat;
        background-size: 100% auto; }

    &.is-open {
        height: 269px; }

    @media screen and (max-width: 960px) {
        .content {
            display: flex;
            height: 58px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            h4 {
                font-size: 20px; } }

        &.is-open .content {
            height: auto;

            h4 {
                font-size: 20px; } } }

    @media screen and (max-width: 374px) {
        .content {
            overflow: hidden;
            align-items: flex-start;
            justify-content: flex-start; } }

    @media screen and (min-width: 768px) {
        .fui-container {
            .action {
                text-align: center; }

            .action .fui-button {
                width: auto;
                margin: 0; } } }

    @media screen and (min-width: 960px) {
        height: 156px !important;

        &::before {
            background: url(#{$publicURL}/resources/cbu/cbu-index/pattern-ad-red-web@2x.jpg) center top no-repeat;
            background-size: 1920px auto; }

        .fui-container {
            height: 156px !important;
            display: flex;
            align-items: center;
            align-content: center;

            img {
                right: auto; }

            .content {
                padding-left: 336px;
                text-align: left;
                flex: 1;
                flex-grow: 1;

                p {
                    margin-bottom: 0; } }

            .action {
                text-align: right; } }

        // &::before
 } }        //     background-image: url(#{$publicURL}/resources/cbu/cbu-index/patternd-arkgray-50-banner.png)

.promotion {
    color: #000;

    .fui-breadcrumb {
        a {
            color: #fff !important; }

        li {
            &::after {
                background: #fff !important; } } }

    .bg-img {
        position: relative;
        width: 100%;
        height: 360px;
        background-position: center;
        background-size: cover; }

    .slick-dots {
        position: absolute;
        display: block;
        text-align: center;
        list-style: none;
        width: 100%;
        bottom: $space * 5;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            width: 40px;
            height: 3px;
            background: $black50;
            margin: 0 3px;

            &.slick-active {
                background: white; }

            button {
                opacity: 0;
                position: relative;
                width: 100% !important;
                top: -10px;
                margin-top: -4px;
                display: inline-block;
                cursor: pointer;
                height: 10px !important;
                vertical-align: middle !important; } } }

    .cards {
        margin-top: -18px; }

    .essential-slider {
        margin-bottom: 60px; } }

@media screen and (max-width: 960px) {
    .promotion {
        .essential-slider {
            margin-bottom: 30px; }

        .cards {
            margin-bottom: -10px;
            margin-top: -46px; } } }
