// .fui-nav-anchor + section.fui-breadcrumb-container
//     top: 152px

.fui-nav-anchor.is-fixed + section.fui-breadcrumb-container {
    top: 45px; }

section.fui-breadcrumb-container {
    display: none;
    width: 100vw;
    padding: 30px 0 24px;
    margin: 0;
    background: none !important;

    .fui-container {
        max-width: 1214px; }

    ul.fui-breadcrumb {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            display: inline-block;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 2px;
                bottom: 0;
                margin: auto;
                width: 1px;
                height: $space * 2.2;
                display: block;
                background: $black50;
                transform: rotate(40deg); }

            a {
                color: $black50;
                font-size: 0.875rem;
                font-weight: $font-light;
                padding: 0 ($space * 2);

                &::after {
                    display: none; } }

            &:last-child > {
                line-height: 0;

                a {
                    font-weight: $font-medium;
                    display: inline-block;
                    overflow: hidden;

                    // width: 150px
                    white-space: nowrap;
                    vertical-align: middle;

 } }                    // text-overflow: ellipsis

            &:last-child::after {
                display: none; }

            &:first-child > a {
                padding-left: 0; } }

        &.is-white li {
            &::after {
                background: $white; }

            a {
                color: $white; } } } }

.fui-nav-tab + .fui-breadcrumb-container,
.fui-nav-anchor + .fui-breadcrumb-container {
    top: 145px; }

.is-help-center header.fui-header ~ main .fui-nav-anchor + .fui-breadcrumb-container {
    top: 105px; }

// .is-ebu:not() div[data-cq-data-path*='header'] ~ div[data-cq-data-path*='breadcrumb'] .fui-breadcrumb-container
//     top: 145px

@media screen and (min-width: 960px) {
    section.fui-breadcrumb-container {
        display: block;
        position: absolute;
        z-index: 19;
        top: 0; } }
