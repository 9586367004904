section.next-article {
  padding: 30px 0 60px;
  .fui-container-article {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    h6 {
      margin: 6px 0 0; } }
  .next-article--prev {
    display: flex;
    width: 278px;
    align-items: center;
    img {
      min-width: 42px;
      transform: rotate(180deg);
      margin-right: 12px; } }
  .next-article--next {
    display: flex;
    width: 278px;
    align-items: center;
    justify-content: flex-end;
    img {
      min-width: 42px;
      margin-left: 12px; } }
  @media screen and ( max-width: 1200px ) {
    .fui-container-article {
      padding: 0!important; } }
  @media screen and ( max-width: 960px ) {
    padding-top: 12px;
    .fui-container-article {
      display: flex; }
    .next-article--next, .next-article--prev {
      width: auto; } } }
