section.video-carousel.fui-horzonal-cards {
  position: initial;
  z-index: 2!important;
  .single-video {
    .slick-list .slick-slide {
      width: 100%!important; } }
  .mask {
    height: 97px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, rgba(32, 32, 32, 0), rgba(32, 32, 32, 0.1));
    z-index: 0; }
  .video-img {
    height: 460px;
    width: 100%;
    background-size: cover;
    background-position: center; }
  .video-card-text {
    position: absolute;
    margin-left: 20px;
    bottom: 20px;
    border-left: 6px solid $accent;
    color: $white;
    padding-left: 12px;
    z-index: 1; }
  .video-card {
    position: relative;
    cursor: pointer;
    &::before,&::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2; }
    &::before {
      width: 60px;
      height: 60px;
      background: $white;
      opacity: .84;
      border-radius: 30px;
      box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }
    &::after {
      left: 20px;
      width: 0;
      height: 0;
      border-left: 16px solid $main;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 16px solid transparent; } } }

@media screen and (max-width: 768px) {
  section.video-carousel.fui-horzonal-cards {
    .video-card {}

    .slick-list {
      .slick-slide {
        // padding-right: 20px
        width: 340px !important; } }
    .video-img {
      background-position: center; } } }
