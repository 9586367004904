.fui-pagination {
    padding: 0;
    margin: ($space * 6) 0;
    display: block;
    list-style: none;
    text-align: center;

    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 ($space * 1.5);

        span {
            height: 30px;
            line-height: 30px; }

        .current-page {
            display: inline-block;
            font-size: 0.875rem;
            padding: 0 10px;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            color: $white;
            background: $grayblue;
            border-radius: 20px;

            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 12px;
                vertical-align: middle;
                background: #bfbfbf;
                margin-left: 30px;
                margin-right: -30px;
                transform: rotate(40deg); }

            + span {
                margin-left: 40px; } }

        a,
        a:link {
            font-size: 0.875rem;
            padding: 0 10px;
            min-width: 30px;
            height: 30px;
            line-height: 30px;
            display: block;
            color: $black50 !important;
            border-radius: 20px;

            &:hover {
                color: $black50;
                background: $lightgray100; } }

        &.disabled > a,
        &.disabled > a:link,
        &.disabled > a:hover {
            color: $gray70 !important;
            background: none;
            cursor: default; }

        &.previous,
        &.next {
            margin: 0 3px; }

        &.previous a,
        &.next a {
            width: 30px;
            height: 30px;
            line-height: 30px;
            padding: 0;
            text-align: center;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center;

            * {
                font-size: 1.5rem; }

            &:hover {
                background: none; } }

        &.selected > a,
        &.selected > a:link,
        &.selected > a:hover,
        &.selected > a:focus {
            color: $white !important;
            background: $grayblue; } } }
