.manage-newsletter {
    padding-bottom: 125px;

    h4 {
        margin: 46px 0 18px 0; }
    .open-detail {
        margin-bottom: 30px; }

    .text-input {
        min-width: initial; }

    .option-name {
        position: relative;

        h6 {
            &::after {
                content: "";
                width: 8px;
                height: 8px;
                background-color: #f05c38;
                border-radius: 50%;
                position: absolute;
                margin-left: 4px; } } }

    .form-group {
        margin-bottom: 42px; }

    .radio-buttons {
        display: flex;
        margin-bottom: 0px;

        .radio-group {
            display: flex;
            align-items: center;

            p {
                display: inline-block;
                margin: 0; }

            .radio-button {
                width: auto;
                margin-right: 72px; } } }

    .recaptcha {
        margin-bottom: 32px; }

    .newsletter-checkbox-group {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        .checkbox {
            height: 30px;
            margin: 0; }

        .detail {
            font-weight: 500;
            margin-left: 6px;
            cursor: pointer;
            display: inline-flex;

            i {
                font-size: 24px;
                margin-top: -2px;
                margin-left: 6px; } } }

    .radio-button {
        position: relative;
        padding-left: 35px;

        // margin-bottom: 12px
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        margin-right: 72px;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer; }

        &:hover input ~ .checkmark {
            background-color: $lightgray100; }

        input:checked ~ .checkmark {
            background-color: $business50;
            border: 2px solid $business50; }

        input:checked ~ .checkmark:after {
            display: block; }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 2px solid $gray70;

            &:after {
                content: "";
                position: absolute;
                display: none;
                top: 8px;
                left: 8px;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background: white; } } } }

@media screen and (max-width: 960px) {
    .manage-newsletter {
        .fui-banner {
            .fui-container {
                height: 100%; } }
        .newsletter-checkbox-group {
            align-items: end; }

        button {
            width: 100%; }

        .radio-buttons .radio-group .radio-button {
            margin-right: 70px;

            p {
                font-size: 14px; } }

        .radio-buttons .radio-group .radio-button {
            &:nth-last-child(1) {
                margin-right: 0; } } } }
