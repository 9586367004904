.tech {
    .fui-tab-pane {
        display: none;
        opacity: 1; }

    .fui-tab-pane.is-active {
        display: block; }

    .fui-nav-anchor {
        margin-bottom: 0; }

    #tech-content {
        padding-bottom: 112px;
        margin-top: 12px; }

    .fui-container {
        img {
            width: 100%; } }

    .fui-breadcrumb-container {
        position: relative;
        top: 0; }

    .fui-nav-anchor + .fui-breadcrumb-container {
        position: relative;
        top: 0;
        margin-bottom: 46px; }

    .has-more-tab {
        button {
            background-color: #FAFAFA; } }

    a.expand {
        color: $black50;
        display: block;
        text-align: center;
        margin: 36px 0;
        font-weight: 500;

        &::after {
            display: none; }

        i {
            vertical-align: middle;
            margin-left: 6px; } }

    .no-more {
        color: #bfbfbf;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default !important;

        i {
            font-size: 30px;
            margin-right: 6px; } } }

@media screen and (max-width: 960px) {
    .tech {
        .load-more {
            margin-bottom: 0; }

        .info {
            padding: 60px 0 0 0; } }

    @media screen and (max-width: 480px) {
        .tech {
            .selected-article {
                margin: 0 -15px; }

            .img-wrapper {
                .main-body {
                    max-height: 194px; } }

            #tech-content {
                padding-bottom: 60px; } } } }
