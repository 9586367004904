header.fui-header .fui-sidebar {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: -100vw;
    background: $white;
    transition: all 0.3s ease;
    overflow-x: hidden;
    overflow-y: auto;

    a,
    a:link,
    a:visited {
        &::after {
            display: none !important; } }

    &.has-menu-open {
        overflow-y: hidden; }

    .sidebar-header {
        position: relative;
        height: 50px;
        display: block;
        box-shadow: 0 2px 0 0 #E9E9E9;

        .fui-switch {
            position: absolute;
            width: 116px;
            height: 25px;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto; }

        .close {
            position: absolute;
            display: block;
            width: 50px;
            height: 50px;
            right: 0;

            &::before {
                transform: rotate(45deg); }

            &::after {
                transform: rotate(-45deg); }

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 24px;
                display: block;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background: $black100; } } }

    .entrance {
        border-top: 1px solid $lightgray100;
        display: flex;
        padding: 0 16px;
        background: #F9F9F9;
        box-shadow: 0 1px 0 0 #E3E7EB;

        > div.entrance-item {
            flex: 1 1 0;
            flex-grow: 1;
            padding: 6px 0;
            text-align: center;

            .icon {
                text-align: center;

                img {
                    width: 48px; } }

            .text {
                font-size: 16px;
                font-weight: $font-medium;
                letter-spacing: 0.2px; } } }

    .user-section {
        padding: $space ($space * 3);
        display: block;
        font-weight: $font-bold;

        * {
            display: inline-block;
            vertical-align: middle; }

        + .menu {
            border-top: 1px solid $gray30; }

        .avatar {
            border-radius: 0;
            bottom: -$space; }

        .login-menu {
            float: right;
            margin-top: 16px;

            .fui-dropdown.is-show {
                .fui-menu {
                    display: block; } }

            .fui-menu {
                display: none;

                .menu-wrapper {
                    width: 100%;

                    .fui-menu-group {
                        display: block; }

                    .fui-item {
                        text-align: left; } } } }

        span {
            margin-left: $space * 2;

            &.greetings {
                font-size: 18px;
                font-weight: $font-medium;

                + a {
                    float: right;
                    margin-top: 16px; } } }

        a:link,
        a:link span {
            font-size: 16px; } }

    .menu-footer {
        margin-top: $space * 1.5;
        margin-bottom: $space * 1.5;

        .fui-item {
            height: 64px;
            line-height: 64px;
            padding: 0 30px;

            img {
                height: 42px; }

            .content {
                padding-left: 12px;
                cursor: pointer; } } }

    .menu {
        .item {
            position: relative;

            .menu-header {
                display: flex;
                box-shadow: 0 1px 0 0 #E8ECF0;
                align-content: center;
                align-items: center;
                cursor: pointer;

                .action {
                    padding: 0 30px;

                    [class*="icon-"] {
                        display: block;
                        font-size: 20px;
                        transition: transform 0.3s ease; } }

                .content {
                    font-size: 1.125;
                    padding: 0 30px;
                    flex: 1 1 0;
                    flex-grow: 1 1 0;
                    height: 72px;
                    line-height: 72px; } }

            .menu-content {
                display: block;
                overflow: hidden;
                width: 100%;
                height: 0;
                background: #F9F9F9;

                .fui-item {
                    background: #F9F9F9;
                    border-top: 1px solid #E3E7EB;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 30px;
                    cursor: pointer; }

                .scroll {
                    padding-left: 10px;
                    overflow-x: auto; } }

            &.is-collapse {
                &::before,
                &::after {
                    transition: all 0.3s ease; }

                &::after {
                    content: "";
                    top: 0;
                    left: 0;
                    position: absolute;
                    border-left: 12px solid #EF3123;
                    border-right: 12px solid transparent;
                    border-top: 36px solid transparent;
                    border-bottom: 36px solid transparent;
                    transform: translateX(-12px);
                    z-index: 3; }

                &::before {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 100%;
                    background: #D3060F;
                    left: 0;
                    top: 0;
                    transform: translateX(-12px);
                    z-index: 3; }

                &:active {
                    .menu-header {
                        color: $accent; } }

                &.menu-open {
                    &::after,
                    &::before {
                        transform: translateX(0px); }

                    .menu-header {
                        color: $accent;

                        .action [class*="icon-"] {
                            transform: rotate(180deg); } }

                    .menu-content {
                        height: auto;
                        display: block; } } } } } }

header.fui-header .fui-sidebar {
    .menu-footer .fui-item:hover {
        color: $accent; } }

.is-ebu header.fui-header .fui-sidebar {
    .menu-footer .fui-item:hover {
        color: $business30; } }

.is-ebu header.fui-header .fui-sidebar .menu {
    .item:active,
    .item.menu-open {
        .menu-header {
            color: $business30; } }

    .item {
        &::before {
            background: #0F3B81; }

        &::after {
            border-left-color: #3966DA; } } }

@media screen and (min-width: 960px) {
    header.fui-header .fui-sidebar {
        display: none; } }

@supports (-webkit-touch-callout: none) {
    header.fui-header .fui-sidebar {
        padding-bottom: 75px; } }
