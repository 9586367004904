.captcha {
  .form-group {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    input {
      min-width: auto;
      margin-right: 6px; }
    .captcha-number {
      margin-right: 6px;
      margin-top: 6px; }
    .captcha-box {
      display: flex;
      align-items: flex-start;
      flex-direction: row; }
    .captcha-reload {
      border: 0; }
    .form-control {
      max-width: 286px; } }
  @media screen and (max-width: 480px) {
    .form-group {
      flex-flow: column wrap;
      .form-control {
        order: 1;
        margin-top: 12px;
        max-width: 100%; }
      .captcha-box {
        order: 0; } } } }
