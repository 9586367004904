.quote-banner {
    // height: 288px
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 288px;

    .border-bottom {
        position: relative;
        display: inline-block;
        margin: 0;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 12px solid rgba(240, 92, 56, 0.4); }

        &.is-blue30 {
            &:after {
                border-bottom: 21px solid rgba(155, 223, 223, 0.4); } }

        &.is-green30 {
            &:after {
                border-bottom: 21px solid rgba(0, 255, 218, 0.4); } } }
    .tools-list {
        @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
            margin-top: 26px; } }
    &.is-white {
        .tools-list .with-comma {
            &:before,
            &:after {
                background: url(/resources/cbu/life-circle-drama/images/quotation.svg) no-repeat; }

            &.is-text-white:after {} } }

    @media screen and (max-width: 960px) {
        min-height: 162px;

        br {
            display: none; } } }

.fui-banner.is-estore,
.fui-banner.is-exclusive {
    height: 500px;
    padding: 0;
    min-height: 0;

    // margin-top: 0 !important

    .fui-arrow {
        position: absolute;
        bottom: 30px;
        left: 15px;

        i {
            font-size: 24px; } }

    .bg {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 500px;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            margin: auto;
            left: 0;
            top: auto;
            bottom: 0;
            object-fit: cover;
            object-position: bottom; } }

    .slick-initialized {
        .slick-slide {
            position: relative;
            height: 500px;
            overflow: hidden; }

        ul.slick-dots {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            padding: 0;
            height: 4px;
            bottom: 18px;
            text-align: center;
            list-style: none;
            vertical-align: bottom;

            li {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 4px;
                margin: 0 3px;
                background-color: $black50;
                opacity: 0.25;
                vertical-align: top;

                button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer; }

                &.slick-active {
                    opacity: 1; } } } }

    .caption {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: $space * 3;

        .fui-container {
            padding: 0 15px; }

        .tag {
            position: relative;
            color: $accent;
            display: inline-block;
            font-size: 12px;
            height: 31px;
            line-height: 31px;
            margin-bottom: 12px;
            font-weight: $font-medium;

            .tag-bg {
                position: absolute;
                margin: 0 auto;
                width: calc(100% - 36px);
                left: 0;
                right: 0;
                top: 0;
                display: block;
                height: 31px;
                border-top: 3px solid $accent;
                border-bottom: 3px solid $accent;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: -3px;
                    background-repeat: no-repeat; }

                &::before {
                    width: 18px;
                    height: 45px;
                    left: -18px;
                    background-image: url(#{$publicURL}/resources/cbu/estore/tag-shape-01@2x.png);
                    background-position: left top;
                    background-size: 19px auto; }

                &::after {
                    width: 18px;
                    height: 31px;
                    right: -18px;
                    background-image: url(#{$publicURL}/resources/cbu/estore/tag-shape-02@2x.png);
                    background-position: right top;
                    background-size: auto 31px; } } }

        h1 {
            margin: 0 0 12px; }

        h5 {
            font-size: 18px;
            height: 54px;
            margin: 0 0;

            @include ellipsis(2); }

        .action {
            padding-top: $space * 3;

            .fui-button {
                min-width: 166px; } } }

    @media screen and (min-width: 600px) {
        .bg img {
            height: 500px;
            width: auto;
            top: 0;
            left: -100%;
            right: -100%; }

        .slick-initialized ul.slick-dots {
            transition: all 0.5s;
            bottom: $space * 6;

            li {
                width: 50px; } }

        .caption {
            padding-top: 90px;

            .tag {
                font-size: 1rem; }

            h5 {
                font-size: 22px;
                height: 66px; } } }

    @media screen and (min-width: 1440px) {
        .bg img {
            width: 100%;
            height: auto;
            top: -100%;
            bottom: -100%; } } }

.fui-banner.is-exclusive {
    height: 450px;

    .bg,
    .slick-initialized .slick-slide {
        height: 450px; }

    .caption .action {
        display: flex;

        > * {
            min-width: 0;
            width: 50%;
            padding-left: $space * 3;
            padding-right: $space * 3;
            max-width: 220px; } }

    @media screen and (max-width: 374px) {
        .bg img {
            height: 100%; }

        .caption .action .fui-button {
            min-width: 142px; } }

    @media screen and (min-width: 768px) {
        width: auto;
        height: 500px;

        .bg,
        .bg img,
        .slick-initialized .slick-slide {
            height: 500px;

            .caption .fui-container {
                width: 90%; } }

        .bg img {
            max-width: 1920px; } }

    &.is-promotion {
        height: 470px;

        &.is-height-460 {
            height: 460px;

            .caption {
                padding-top: 40px;

                .caption-wrapper {
                    max-width: 52%; } }

            @media screen and (max-width: 640px) {
                height: 360px;

                .caption {
                    padding-top: 0;
                    margin-top: -10px;

                    .caption-wrapper {
                        max-width: 100%; }

                    h1 {
                        font-size: 28px;
                        margin-bottom: 0; } } } }

        .bg,
        .slick-initialized .slick-slide {
            background-color: $white;
            height: 470px; }

        .caption-wrapper {
            max-width: 50%; }

        .caption .description {
            height: auto;
            margin-bottom: 30px;
            font-size: 22px;

            .list {
                display: block;
                align-content: center;
                flex-direction: column;

                h6 {
                    margin: 0;
                    display: flex;
                    align-content: center; }

                img {
                    width: 30px;
                    margin-right: 6px;
                    margin-bottom: -8px; }

                img, h5 {
                    display: inline-block;
                    height: auto;
                    font-size: 18px; }

                h5 {
                    display: inline; } } }

        .is-secondary {
            background: $white; }

        .fui-arrow {
            display: none !important; }

        @media screen and (max-width: 600px) {
            .caption-wrapper {
                max-width: 100%; }

            .caption .description {
                font-size: 16px;
                margin-bottom: 14px;

                .list {
                    height: 32px;
                    margin-bottom: 0;
                    overflow: hidden;

                    img {
                        width: 24px;
                        margin-bottom: -4px; } } }

            .estore-banner-item {
                .bg {
                    img {
 } } } }                        // bottom: -11vh

        @media screen and (max-width: 480px) {
            .estore-banner-item {
                .bg {
                    img {
 } } } }                        // bottom: -15%

        @media screen and (min-width: 960px) {
            ~ .fui-nav-tab.is-box-tab {
                margin-top: -37.5px; } } }

    &.dark-theme {
        .caption {
            h5, .fui-button span, h1,.description {
                color: $white; }
            .action {
                .fui-button {
                    span.text {
                        color: inherit; } } } }

        .slick-dots {
            li {
                background-color: $white !important; } } }
    &.white-theme {
        .caption {
            h5, .fui-button span, h1,.description {
                color: $black50; } }

        .slick-dots {
            li {
                background-color: $black50 !important; } } } }

.fui-banner {
    position: relative;
    overflow: hidden;
    background-position: left center;
    background-size: cover;
    z-index: 3;
    padding-top: 0;
    min-height: 620px;

    &.top-0 {
        top: 0 !important; }

    // &.fui-banner.is-product.is-style-2
    //     min-height: auto
    //     height: 360px
    //     margin-top: 0!important
    //     margin-bottom: 42px

    //     .caption
    //         top: 0

    //     p.subtitle
    //         h6
    //             margin: 0 !important
    &.is-product.is-prmotion-banner.is-style-2 {
        h1 {
            overflow: visible !important; }

        .caption {
            color: $black100;

            h3 {
                margin-bottom: 0; }

            p {
                margin-bottom: 42px;
                margin-top: 4px;
                font-weight: normal; } }

        &.content-right {
            .caption {
                right: 10%;
                position: absolute;
                width: auto;
                top: 80px !important; } }

        @media screen and (max-width: 960px) {
            .caption {
                width: 50%;
                padding-right: 0; } }

        @media screen and (max-width: 599px) {
            &.content-right {
                .caption {
                    right: auto;
                    left: auto;
                    top: 156px !important;
                    max-width: 230px; } }

            .caption {
                right: auto;
                left: 30%;
                width: 70%;
                top: 156px !important;

                br {
                    display: none; }

                h3 {
                    font-size: 22px; }

                p {
                    font-size: 14px;
                    line-height: 1.5; } } } }

    &.is-295 {
        height: 295px;
        min-height: auto !important;

        .caption {
            top: 36px !important;

            h1 {
                font-size: 28px !important; } } }

    &.is-360 {
        height: 360px;
        min-height: auto !important;

        .caption {
            top: 64px !important; } }

    &.only-title {
        .caption {
            top: 140px !important; } }

    &.is-500 {
        height: 500px;
        min-height: auto !important;

        .caption {
            top: 64px !important; } }

    &.is-text-dark {
        .caption {
            h1, .subtitle {
                color: $black100; } } }

    &.is-rate-plan {
        .fui-container {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;

            .caption {
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0;
                top: 0 !important;

                .subtitle {
                    margin-bottom: 0; } } } }

    &.is-adaptive {
        min-height: auto;
        background-position: center;

        .fui-container {
            height: 100%;
            top: 0; }

        h1 {
            display: flex;
            align-items: center;
            margin: 0;
            color: $white;
            height: 100%;
            padding: 0 15px; } }

    &.fui-cbu {
        background: $white; }

    &.fui-cbu,
    &.fui-ebu {
        overflow: visible;

        .fui-tab-container {
            .fui-tab {
                height: auto;
                overflow: hidden; } }

        + .fui-tab-pane {
            margin-bottom: 0; } }

    &.is-search {
        padding: 30px 0 24px;
        min-height: 0;
        background: $lightgray100;
        overflow: visible;
        background: url(#{$publicURL}/resources/common/images/ebu-banner-search.png) center no-repeat;
        background-size: cover;

        .fui-container {
            position: relative;
            z-index: 9;
            padding: 0 ($space * 2);

            &.keyword-container {
                z-index: 0;
                padding-right: 0; } }

        h2 {
            line-height: 36px;
            font-size: 1.5rem;
            margin-bottom: $space * 1.5; } }

    &.is-form {
        min-height: 170px;
        height: 170px;
        background-color: $gray50;
        color: $white;
        background-size: cover;
        background-position: center;

        .fui-container {
            top: 0;
            display: flex;
            align-items: center;
            padding: 0 ($space * 2);
            margin-top: 0;
            height: 100%; }

        h1 {
            margin-bottom: 0;
            width: 100%; } }

    &.is-product {
        min-height: 450px;
        background-color: $navy50;
        background-position: center;
        background-size: cover;
        padding: 0 !important;
        z-index: 0;

        .fui-container {
            width: 90%;
            max-width: 1200px;
            padding: 0 15px;
            margin: 0 auto;
            position: relative;

            .caption {
                padding-left: 0; } }

        @media screen and (max-width: 960px) {
            .fui-container {
                width: 100%; } }

        > img,
        .slick-slide {
            .banner-img > div {
                background-position: center;
                background-size: cover; }

            img {
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%; } }

        &.multi-slide {
            .slick-slider ul.slick-dots {
                bottom: 60px; }

            .slick-slider .slick-slide > div .caption {
                top: 20px; }

            @media screen and (max-width: 960px) {
                .slick-slider ul.slick-dots {
                    bottom: 172px; } } }

        &.is-section-banner-1 {
            h1 {
                @include ellipsis(2); } }

        &.is-style-2 {
            margin-bottom: 0;

            .banner-img > div {
                top: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                background-position: center;
                background-size: cover; }

            .caption {
                position: relative;
                width: 50%;
                top: 76px;
                padding-top: 0;

                h1 {
                    margin: 0;
                    font-size: 32px;

                    @include ellipsis(2); }

                .subtitle {
                    margin: 12px 0 20px;

                    @include ellipsis(2);

                    .list {
                        display: flex;
                        align-items: center;
                        margin-bottom: 6px;

                        h5 {
                            display: inline;
                            margin: 0; }

                        img {
                            margin-left: 0; } }

                    > p {
                        margin: 0; }

                    h6 {
                        display: flex;
                        align-items: center;
                        margin: 0;

                        img {
                            margin: 0;
                            margin-right: 6px; }

                        &:nth-of-type(1) {
                            margin-top: 34px; } } }

                &::before {
                    content: none;
                    margin-bottom: 36px; } }

            @media screen and (max-width: 1180px) and (min-width: 960px) {
                margin-bottom: 36px; }

            @media screen and (max-width: 960px) {
                .caption {
                    position: relative;
                    width: 100%;
                    padding-right: 75px;
                    top: 33px !important;

                    h1 {
                        font-size: 28px;
                        line-height: 1.5; }

                    .subtitle {
                        margin: 6px 0 18px;

                        img {
                            width: 24px;
                            margin-top: 3px; }

                        .list {
                            align-items: end;

                            h5 {
                                font-size: 18px;
                                overflow: hidden;
                                height: 26px; } } } } } }

        .slick-slider {
            ul.slick-dots {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                padding: 0;
                height: 4px;
                bottom: 30px;
                text-align: center;
                list-style: none;
                vertical-align: bottom;

                li {
                    display: inline-block;
                    width: 50px;
                    height: 4px;
                    margin: 0 3px;
                    background-color: $gray100;

                    button {
                        opacity: 0; }

                    &.slick-active {
                        background-color: $white; } } }

            .slick-slide,
            .slick-slide > div {
                position: relative;
                min-height: 468px;

                .bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-position: center;
                    background-size: cover; }

                .caption {
                    position: absolute;
                    width: 100%;
                    max-width: 700px;
                    top: 105px;
                    bottom: auto;

                    h1 {
                        line-height: 42px;
                        font-size: 28px;

                        @include ellipsis(2); } } } }

        .caption {
            position: absolute;
            max-width: 620px;
            bottom: auto;
            padding: 46px 15px 0 15px;
            color: $white;
            top: 46px;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                top: 0;
                height: 36px;
                background: url(#{$publicURL}/resources/common/images/quotation.png) no-repeat left top;
                background-size: 36px;
                margin-bottom: $space * 1.5; }

            .subtitle {
                margin-bottom: $space * 4.5; } } }

    &.__bg-transparent {
        background-color: transparent; }

    &.fade > .fui-banner-bg {
        opacity: 0; }

    .fui-container {
        position: absolute;
        padding: 0;
        left: 0;
        right: 0;
        width: 100%;

 }        // height: 100%

    .fui-tab-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        background: none;
        box-shadow: 0 18px 36px 0 rgba(75, 88, 105, 0.24);

        @media all and (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
            top: 0; }

        .fui-tab-header {
            background: #FFF;

            .fui-tab .fui-tab-item {
                padding: 12px 12px 0; } }

        .fui-tab-body {
            position: absolute;
            bottom: 0;
            background: none;
            z-index: 2;

            .fui-tab-pane {
                max-width: 100%;
                padding: 0 15px ($space * 6);

                .fui-button {
                    height: 50px;
                    display: block;
                    max-width: 180px;

                    span {
                        line-height: 50px; }

                    &.is-secondary {
                        span {
                            line-height: 46px; } } }

                h3.tab-title {
                    font-size: 32px;
                    line-height: 48px;
                    margin-top: $space * 4;
                    margin-bottom: 0;
                    width: 70%;
                    max-width: 400px; }

                @media screen and (min-width: 960px) {
                    h3.tab-title {
                        width: 100%; }

                    .fui-button {
                        display: inline-block; } } } } }

    &.is-fifty-fifty {
        .caption {
            position: relative;
            width: 50%;
            top: 76px;
            padding-top: 0; } }

    .fui-banner-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 80px;
        left: 0;
        display: block;
        overflow: hidden;

        img.kv-bg {
            opacity: 0;
            transition: opacity 2s ease; }

        &.fade-out img.kv-bg {
            opacity: 0; }

        &.is-active img.kv-bg {
            opacity: 1; }

        .main-image {
            position: absolute;
            width: 100%;
            height: auto;
            top: -40px; }

        img.trapzoidal {
            position: absolute;
            height: 100%;
            left: -90px;
            bottom: 24px; }

        img.triangle {
            position: absolute;
            width: 100px;
            left: 15%;
            top: 18%; } }

    &.fui-cbu {
        min-height: 570px;
        height: 570px;

        .fui-banner-bg {
            height: 490px; }

        .fui-container {
            height: 100%;

            .fui-tab-body {
                bottom: 40px;

                .fui-tab-pane {
                    padding-bottom: 0;

                    .fui-dropdown.for-banner {
                        margin-bottom: 0;

                        @media screen and (max-width: 374px) {
                            max-width: 72%; } }

                    .tab-link-content {
                        > div {
                            position: relative;
                            display: block;
                            margin-top: 18px;

                            &:first-child > a.tab-link:link {
                                margin-top: 0; } } }

                    a.tab-link,
                    a.tab-link:link {
                        font-size: 24px;
                        position: relative;
                        display: inline-block;
                        font-weight: $font-medium;

                        &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background: $black50; } }

                    @media screen and (min-width: 960px) {
                        padding-bottom: $space * 5;

                        .tab-link-content {
                            height: 260px;
                            column-width: 180px;
                            column-count: 2;
                            column-gap: 90px;
                            column-span: all;
                            margin-top: -$space * 4;

                            > div {
                                padding-top: $space * 4;
                                margin-top: 0; }

                            a.tab-link,
                            a.tab-link:link {
                                display: block;
                                font-size: 28px; } } } } } }

        .kv-arrow {
            position: absolute;
            max-width: 1920px;
            height: 100%;
            top: 80px;
            max-height: 490px;
            width: 100%;
            overflow: hidden; }

        .kv-arrow img {
            position: absolute;
            max-width: 1920px;
            height: auto;
            width: 100%;
            top: 0;
            left: -100%;
            right: -100%;
            margin: auto;
            z-index: 1;

            @media screen and (min-width: 510px) {
                width: 100%;
                height: auto; } }

        .kv-bg {
            position: absolute;
            max-width: 1920px;
            margin: auto 0 auto auto;
            height: auto;
            width: 100%;
            z-index: 0;

            @media screen and (min-width: 510px) {
                right: 0;
                left: auto; }

            @media screen and (min-width: 510px) and (max-width: 959pc) {
                height: auto;
                width: 100%; } }

        .fui-tab.with-icon {
            .fui-tab-item {
                .icon,
                .icon * {
                    width: 30px;
                    margin: 0 auto; } } }

        .fui-dropdown {
            max-width: 75%;
            margin-bottom: 0;

            .fui-dropdown-item {
                i.icon-chevron-down {
                    align-self: flex-end;
                    margin-bottom: 10px; } } }

        .action {
            margin: ($space * 3) 0;

            .fui-button {
                margin: 0 $space $space 0; } }

        .fui-tab-body {
            padding-top: 52px; }

        .fui-banner-tags {
            .fui-button.is-label {
                min-width: 0px !important;
                height: 34px;
                line-height: 30px;
                padding: 0 12px;
                text-align: center;
                font-size: 0.875rem;
                border: solid 2px $gray30;
                border-radius: 30px;
                margin-right: 6px;
                margin-bottom: 6px;

                &:hover {
                    border-color: $black50; }

                @media screen and (max-width: 374px) {
                    padding: 0 8px;
                    margin-right: 6px; } } }

        @media screen and (min-width: 960px) {
            .fui-container {
                top: 0;

                .fui-tab-container {
                    top: 95px;

                    .fui-tab-body {
                        bottom: 0;

                        .fui-tab-pane {
                            padding-top: 0; } } } }

            .kv-arrow,
            .fui-banner-bg {
                height: 620px;
                max-height: 620px; }

            .kv-arrow img {
                height: 100%;
                width: auto; }

            .kv-bg {
                width: auto;
                height: 100%;
                left: -100%;
                right: -100%;
                margin: auto;

 } }                // margin: auto 0 auto 5%

        @media screen and (min-width: 960px) and (max-width: 1920px) {
            .kv-bg {
                height: 100%;
                width: auto; } }

        @media screen and (min-width: 1920px) {
            .kv-bg {
                height: auto;
                width: 100%; } } }

    @media screen and (min-width: 600px) and (max-width: 812px) and (orientation: landscape) {
        &.is-solution .slick-slider .slick-slide img.d-md-block.d-none,
        img.d-sm-block.d-none {
            display: block !important;
            height: 100%;
            width: auto;
            max-width: none; }

        img.d-sm-none.d-block {
            display: none !important; } }

    @media screen and (max-width: 960px) {
        &.is-360 {
            height: 200px !important;

            .caption {
                position: relative;
                top: 0 !important; } }

        &.fui-cbu {
            .fui-container .fui-tab-body .fui-tab-pane .fui-dropdown {
                max-width: 350px; } } }

    @media screen and (max-width: 960px) and (min-width: 540px) {
        &.fui-cbu {
            .kv-arrow img {
                top: -20%; } } }

    @media screen and (max-width: 540px) and (min-width: 376px) {
        &.fui-cbu {
            .kv-arrow img {
                top: -25px; } } }

    @media screen and (min-width: 768px) {
        .fui-banner-bg {
            img.triangle {
                left: 15%;
                top: 28%;
                width: 15%; }

            img.trapzoidal {
                height: 120%;
                left: -240px;
                bottom: -1px; }

            .main-image {
                top: 0;
                right: 0;
                width: auto;
                height: 100%; } } }

    @media screen and (min-width: 960px) {
        padding: ($space * 12) 0;

        &.fui-cbu {
            height: 620px;

            .kv-arrow,
            .fui-banner-bg {
                top: 0; }

            .fui-dropdown {
                max-width: 75%;

                .fui-dropdown-item i.icon-chevron-down {
                    align-self: center;
                    margin: 0; } }

            .fui-tab-container .fui-tab-header .fui-tab .fui-tab-item {
                height: 100px;
                padding-top: 20px; } }

        .fui-banner-bg {
            img.triangle {
                left: 55%; } }

        &.is-search {
            padding: 84px 0 35px;
            min-height: 0px; }

        &.is-form {
            min-height: 240px;
            height: 240px;

            .fui-container {
                margin-top: 90px;
                height: initial; }

            img {
                position: absolute;
                height: auto;
                width: 100%; }

            h1 {
                max-width: initial; } }

        &.is-product {
            margin-top: 0;
            min-height: 468px;
            padding: 0;

            img {
                left: 0;
                right: 0;
                margin: auto; }

            .caption {
                top: 80px; }

            &.is-style-2 .slick-slider .slick-slide img {
                width: 100%;
                height: auto; }

            &.is-style-2 .caption,
            &.is-style-2 .slick-slider .slick-slide > div .caption {
                top: 80px;
                bottom: auto;

                h1 {
                    font-size: 42px; } }

            .slick-slider {
                min-height: 468px;

                .slick-slide,
                .slick-slide > div {
                    .caption {
                        // top: auto
                        bottom: 68px;

                        h1 {
                            font-size: 42px !important;
                            line-height: 63px; } } } } }

        .fui-container {
            padding: 0 15px; }

        .fui-tab-container {
            position: relative;
            background: #FFF;
            height: auto;
            max-width: 695px;

            .fui-tab-body {
                position: relative;

                .fui-tab-pane {
                    max-width: 100%;
                    padding: ($space * 4) ($space * 4) ($space * 7);

                    h3.tab-title {
                        font-size: 42px;
                        line-height: 60px;
                        margin-bottom: 0; } } } }

        .fui-tab-container .fui-tab-body .fui-tab-pane h3.tab-title {
            max-width: initial; } }

    @media screen and (min-width: 1200px) {
        .fui-banner-bg {
            .main-image {
                left: -100%;
                right: -100%;
                width: auto;
                height: 100%;
                margin: auto; }

            img.trapzoidal {
                left: 0;
                width: 75%;
                height: auto; } } }

    // @media screen and (max-width: 1440px) and (min-width: 960px)
    //     &.fui-cbu .fui-banner-bg
    //         img.kv-bg
    //             margin-left: 5%

    @media screen and (max-width: 1920px) and (min-width: 960px) {
        &.fui-cbu .fui-banner-bg {
            img.kv-bg {
                height: 100%;
                width: auto;

 } } }                // margin-left: 5%

    @media screen and (min-width: 1921px) {
        &.fui-cbu .fui-banner-bg {
            img.kv-bg {
                height: auto;
                width: 100%;
                max-width: none; } } }

    @media screen and (min-width: 1440px) {
        .fui-banner-bg {
            img.trapzoidal {
                width: 60%;
                height: auto; }

            img.triangle {
                left: 50%; } } } }

.is-eservice .fui-banner.is-form {
    min-height: 147px;
    height: 147px;

    @media screen and (min-width: 960px) {
        min-height: 156px;
        height: 156px;
        padding: 0;

        .fui-container {
            margin-top: 50px;
            width: 90%; } } }

.is-eservice .fui-banner.is-helpcenter {
    min-height: 172px;
    height: 172px;

    @media screen and (min-width: 960px) {
        min-height: 240px;
        height: 240px;

        .fui-container {
            margin-top: 90px; } } }

.fui-nav-anchor + .fui-banner {
    margin-top: 60px; }

.fui-banner.fui-cbu {
    .fui-tab-container,
    .fui-banner-bg,
    .kv-arrow {
        opacity: 0; } }

.fui-banner.fui-cbu.fade-in {
    .fui-tab-container {
        animation: fadeIn 0.5s 1s ease forwards; }

    .fui-banner-bg {
        opacity: 0;
        transform: scale(1.1);
        animation: zoomIn 1s 0.5s ease forwards; }

    .kv-arrow {
        opacity: 0;
        transform: scale(1.1);
        animation: zoomIn 1s ease forwards; } }

.fui-banner.is-search-country {
    top: 0;
    min-height: 460px;
    padding: 140px 0 0 0;
    overflow: visible;
    position: static;

    .fui-container {
        z-index: 99;
        padding: 0 15px; }

    .fui-menu-body {
        background: $white; } }

@media screen and (min-width: 960px) {
    .fui-nav-anchor.is-fixed ~ .fui-nav-tab.is-box-tab {
        margin-top: -80.5px; } }

.fui-banner.is-origin-number-roaming-prepaid-banner {
    height: 460px !important;

    .caption {
        padding-top: 47px;
        h1 {
            height: auto; } }

    .description {
        margin-bottom: 40px!important; }
    .bg {
        // height: 370px!important
        img {
            top: 0;
            margin-top: 0;
            width: 100%;
            height: 100%; } }
    .slick-slide {
        height: 370px; }
    .slick-initialized {
        ul.slick-dots {
            top: 210px; } } }

.fui-banner.is-origin-number-roaming-localcard-banner {
    .caption {
        padding-top: 100px !important; } }

.fui-banner.is-origin-number-roaming-country-banner {
    margin-top: 0 !important;
    height: 360px !important;
    min-height: 360px !important;

    &.is-360 {
        .caption {
            top: 110px !important; } } }

@media screen and (max-width: 960px) {
    .fui-banner {
        &.is-360,
        &.is-360.only-title {
            .caption {
                max-width: 100% !important;
                margin-right: 0;

 } } }                // text-align: left !important

    .fui-banner.is-origin-number-roaming-country-banner {
        .caption {
            margin-right: 0;
            max-width: 100% !important; } } }

@media screen and (max-width: 768px) {
    .estore-banner-item {
        .bg {
            img {
                top: 0;
                bottom: auto;
                margin: 0; } } }

    .slick-initialized {
        .slick-slide {
            height: 360px;

            ul.slick-dots {
                bottom: 20px; } }

        .caption {
            padding-top: 50px; } }

    .fui-banner {
        &.is-360.only-title {
            .caption {
                top: 60px !important; } }

        &.is-product.is-style-2.is-295,
        &.is-295 {
            height: 360px !important;

            .caption {
                top: 130px !important;
                padding-right: 0 !important;

                h1 {
                    font-size: 24px !important; } } }

        &.is-origin-number-roaming-prepaid-banner {
            height: 360px !important; }

        &.is-origin-number-roaming-country-banner {
            .caption {
                padding-top: 90px !important;

                // width: calc(100% - 30px)
                width: calc(100%) !important;
                padding-right: 0 !important;
                text-align: center !important; }

            &.is-360 {
                .caption {
                    top: 33px !important; } } } }

    .fui-banner.is-origin-number-roaming-localcard-banner {
        height: 359px !important;

        .slick-slide {
            height: 359px !important; }

        .caption {
            padding-top: 47px !important;
            background: linear-gradient(to right, #202020, rgba(32, 32, 32, 0) 68%);

            .description {
                margin-bottom: 40px !important;
                @media screen and ( max-width: 400px ) {
                    margin-bottom: 20px!important; } }

            .description,
            h1, h3 {
                text-align: left; } }

        .bg {
            height: 359px !important;
            img {
                object-position: top; } } }

    .fui-banner.is-search-country {
        .menu-autocomplete {
            .MuiGrid-item {
                width: 100%; } } } }
