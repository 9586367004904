.group-extra-link-card {
    display: flex;
    margin-top: 18px;
    flex-direction: column;

    .single-extra-link-card {
        position: relative;
        display: flex;
        margin: 0 0 12px 0;
        box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.12)"};

        .article-content {
            h4 {
                @include ellipsis(2); } } }

    .article-img {
        position: relative;
        flex: 0 0 216px;
        overflow: hidden;
        background-size: cover;
        background-position: center center;

        img {
            width: 100%;
            height: 100%; } }

    .fui-card-ribbon {
        position: absolute;
        left: 0;
        top: 0;
        color: $white;
        background: $coral100;
        font-size: 0.875rem;
        height: 28px;
        line-height: 28px;
        z-index: 9;
        padding: 0 6px;

        &::after {
            content: "";
            float: right;
            display: block;
            margin-right: -34px;
            width: 0px;
            height: 0px;
            border-left: 14px solid $coral100;
            border-top: 14px solid $coral100;
            border-right: 14px solid transparent;
            border-bottom: 14px solid transparent; }

        @media screen and (min-width: 768px) {
            padding: 0 12px;

            &::after {
                margin-right: -40px; } } }

    .article-content {
        padding: 18px;
        background-color: #fff;
        width: 100%;

        h4 {
            margin-bottom: 6px;
            margin-top: 12px;

            @include ellipsis(2);

            min-height: 72px;
            height: 72px; }

        p {
            margin: 0;
            height: 62px;

            @include ellipsis(2); }

        small {
            margin-right: 10px;
            color: $gray100;
            font-weight: 500; }

        .periodical {
            color: $business50; } }

    .fui-card-extra {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .fui-button {
            .text {
                color: #D3060F; } } }

    .social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        i {
            font-size: 30px;
            margin-left: 6px;
            cursor: pointer; } }

    .view {
        color: $gray100;
        display: flex;
        align-items: center; } }

@media screen and (max-width: 960px) {
    .group-extra-link-card {
        margin: 0;

        .single-extra-link-card.fui-card {
            margin: 12px 0 0 0;
            max-height: 128px; }

        .article-img {
            flex: 0 0 120px; }

        .article-content {
            padding: 9px 12px;

            small {
                font-size: 12px; }

            h4 {
                font-size: 16px;
                line-height: 1.75;
                margin: 0;
                min-height: initial;
                height: auto; }

            p {
                display: none; }
            a {
                display: block; } }

        .fui-card-ribbon {
            font-size: 12px; }

        .fui-card-extra {
            margin-top: -3px;

            a.fui-button.is-arrow.hover,
            a.fui-button.is-arrow:hover,
            a.fui-button.is-arrow:active,
            a.fui-button:link.is-arrow.hover,
            a.fui-button:link.is-arrow:hover,
            a.fui-button:link.is-arrow:active,
            a.fui-button:visited.is-arrow.hover,
            a.fui-button:visited.is-arrow:hover,
            a.fui-button:visited.is-arrow:active,
            button.fui-button.is-arrow.hover,
            button.fui-button.is-arrow:hover,
            button.fui-button.is-arrow:active,
            .fui-button.is-arrow.hover,
            .fui-button.is-arrow:hover,
            .fui-button.is-arrow:active {
                color: transparent; } }

        .social {
            display: none; }

        .article-content {
            small {
                color: $gray100; } } } }


.group-extra-link-card.article-card {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    .single-extra-link-card {
        width: 49.5%;
        p {
            color: #4F5E72; }
        .fui-button.is-arrow:hover {
            color: $accent; } }

    @media screen and (max-width: 768px) {
        .single-extra-link-card {
            width: 100%; } }
    @media screen and (max-width: 960px) {
        .article-content {
            h4 {
                min-height: 72px;
                height: 72px; } }
        .fui-card-extra {
            margin-top: -15px; } } }



