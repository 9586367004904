.is-eservice {
    .fui-collapse-menu {
        ul li a:visited:hover {
            color: $red30; } } }
.fui-collapse-menu {
    cursor: pointer;

    &.help-center {
        .collapse-menu-header {
            background: none !important;

            h6.group-name {
                background: url(#{$publicURL}/resources/cbu/discount/collapse-menu-bg-help-center@2x.png) no-repeat left center !important; }

            @media screen and (max-width: 960px) {
                h6.group-name {
                    background: none !important; } } } }

    &.is-dropdown {
        .fui-dropdown {
            width: 100%;

            button.fui-dropdown-item {
                text-align: left;
                width: 100%;
                height: 50px;
                border: 2px solid $lightgray100;
                margin-top: 3px;

                i {
                    float: right; } }

            .fui-menu {
                width: 100%;

                .fui-item {
                    text-align: left;
                    padding-left: $space * 2;
                    padding-right: $space * 2; } } } }
    .third-level {
        display: none; }
    .group-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: url(#{$publicURL}/resources/cbu/product/images/bg-pattern.png);
        height: 60px;
        width: 100%;
        color: #fff;
        padding: 15px;
        font-weight: 500;
        margin: 0;

        i {
            font-size: 24px;
            display: none; } }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            &.menu-active .body1 i {
                transform: rotate(180deg); }

            .body1 {
                margin: 0;
                padding: 15px;
                border-bottom: 2px solid #E2E4E6;
                display: list-item;
                align-items: center;
                justify-content: space-between;
                position: relative; }

            .more-btn {
                * {
                    vertical-align: middle; } }

            p,
            a:link,
            a:visited {
                color: $black50;
                font-weight: 500;
                padding: 15px 15px 15px 15px;
                margin: 0;
                border-bottom: 2px solid #E2E4E6;

                i {
                    font-size: 24px;
                    transition: all 0.3s;
                    position: absolute;
                    right: 15px; }

                &.active,
                &:hover {
                    color: $business30; } }

            .item-group {
                position: relative;
                transform: rotateX(90deg);
                transform-origin: top;
                transition: all 0.3s;
                height: 0;
                opacity: 0;
                .icon-chevron-down {
                    transition: all .3s;
                    display: inline-block;
                    float: right;
                    transform: rotate(0deg); }
                .active {
                    &> p {
                        color: $red30; }
                    .icon-chevron-down {
                        transform: rotate(180deg); }
                    .third-level {
                        display: block;
                        font-size: 14px;
                        margin-top: -14px;
                        background: #f0f2f5;
                        border-bottom: 2px solid #E2E4E6;
                        p {
                            font-size: 14px;
                            border: 0;
                            padding: 6px 0 6px 24px;
                            font-weight: 400; } } }

                a, p {
                    // display: none
                    padding-left: $space * 3; }

                // transition-delay: 0.3s
                &::after {
                    content: "";
                    top: 0;
                    left: 12px;
                    height: 0;
                    position: absolute;
                    border-left: 12px solid $business30;
                    border-right: 12px solid transparent;
                    border-top: 36px solid transparent;
                    border-bottom: 36px solid transparent;
                    transform: translateX(-12px);
                    z-index: 3; }

                &::before {
                    content: "";
                    left: 0;
                    top: -1px;
                    width: 12px;
                    height: 100%;
                    background-color: $navy30;
                    position: absolute;
                    transition: all 0.3s; } }

            &:hover {
                .body1 {
                    color: $business30; } } }

        .menu-active {
            .item-group {
                transform: rotateX(0);

                // transition-delay: 0.3s
                height: auto;
                opacity: 1;

                &::after {
                    height: 0%; } } }

        .active {
            i {
                transform: rotate(180deg); }

            .body1 {
                color: $business30; }

            p.active {
                color: $business30; } } }

    .current-select-item {
        display: none;
        cursor: initial;

        p {
            margin: 0;
            padding: 15px;
            border-bottom: 2px solid #E2E4E6;
            // display: flex
            display: flex!important;
            align-items: center;
            font-weight: 500; } } }

@media screen and (max-width: 960px) {
    .fui-collapse-menu {
        .collapse-menu-group {
            p {
                display: none; }
            &.menu-active {
                p {
                    display: block; } } }
        .current-select-item {
            display: block; }

        .group-name {
            background-image: none;
            color: #000;
            border-bottom: 2px solid #E2E4E6;

            i {
                display: block; } }

        ul {
            transform: rotateX(90deg);
            transform-origin: top;
            max-height: 0;
            opacity: 0;
            transition: all 0.3s ease-out; }

        .collapse-menu-group.menu-active {
            ul {
                transform: rotateX(0deg);
                height: auto;
                opacity: 1;
                max-height: 100%;

                li > a:hover,
                li > a:focus {
                    color: $black50; } } } } }
