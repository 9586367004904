.fui-banner.is-zipcode {
    background: $darkgray100;
    color: $white;
    min-height: 320px;
    background: url(#{$publicURL}/resources/cbu/help-center/images/ebu-helpcenter-call-banner-sm.jpg) center no-repeat;
    background-size: cover;

    img {
        position: absolute;
        width: 100%;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto; }

    .fui-container {
        height: 150px;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0 ($space * 2); }

    @media screen and (min-width: 768px) {
        min-height: 360px;
        background: url(#{$publicURL}/resources/cbu/help-center/images/ebu-helpcenter-call-banner.png) center no-repeat;
        background-size: cover; } }

.help-center-contact .fui-nav-anchor + .fui-banner,
.fui-nav-anchor + .fui-banner.is-help-center {
    margin-top: 0; }

.grid-table.MuiGrid-root {
    .MuiGrid-item {
        margin-left: -1px;

        ~ .MuiGrid-item {
            .fui-table {
                tbody td {
                    width: 50%; }

                thead {
                    display: none;

                    @media screen and (min-width: 600px) {
                        display: contents; } } } } } }

.fui-banner.is-help-center {
    min-height: 360px;
    background-position: top center;
    background-size: cover;
    padding: 80px 0 0;
    background-image: url(#{$publicURL}/resources/cbu/help-center/images/ebu-helpcenter-home-banner-sm.jpg);
    overflow: visible;

    @media screen and (min-width: 600px) and (max-width: 960px) {
        background-position: top right; }

    @media screen and (min-width: 768px) {
        background-image: url(#{$publicURL}/resources/cbu/help-center/images/ebu-help-banner-1440-x-460.png); }

    @media screen and (min-width: 960px) {
        padding-top: 60px;
        background-position: top center; }

    .keyword-container {
        .keyword-action {
            height: 40px;

            .fui-button.is-text {
                > i {
                    color: $white; } } } }

    &.is-faq {
        padding: ($space * 3.5) 0 ($space * 3);
        min-height: 148px;
        background-color: $navy50;
        background-position: left center;
        background-image: url(#{$publicURL}/resources/cbu/help-center/images/bg-helpcenter-banner-sm.png);
        background-size: cover;

        @media screen and (min-width: 960px) {
            padding: 84px 0 35px;
            background-image: url(#{$publicURL}/resources/cbu/help-center/images/bg-helpcenter-banner.png);
            background-size: auto 100%; } }

    .fui-container {
        height: auto;
        position: relative;
        color: $white;
        padding-left: 15px;
        padding-right: 15px;

        h1 {
            margin: 0 0 ($space * 3); }

        h6 {
            margin: 0 0 36px; } }

    .keyword-container {
        padding-right: 0;

        label {
            color: $white; } }

    @media screen and (min-width: 960px) {
        background-size: cover;

        .fui-container {
            h1 {
                margin: 0 0 ($space * 4); } }

        h6 {
            margin: 0 0 ($space * 6); } } }

.fui-horizontal-card {
    .fui-container {
        .fui-cards.no-scrollbar {
            margin-left: -20px; } }

    &.help-center-search-card {
        background: url(#{$publicURL}/resources/cbu/img/help-center-bg.svg) center no-repeat; } }

.is-en {
    .fui-help-section-form {
        padding-top: 24px; }

    @media screen and (min-width: 960px) {
        .fui-help-section-form {
            padding-top: 0; } } }

.fui-help-section-form,
.fui-section-document {
    padding-top: $space * 3;

    .fui-collapse-menu.is-dropdown {
        margin-top: 30px;
        margin-bottom: $space * 6; }

    .fui-document-section {
        position: static;
        padding-top: 0;
        padding-bottom: 60px;

        .fui-cards {
            padding: ($space * 3) 0 0;
            margin-left: 0;
            margin-right: 0; }

        h3 {
            margin-bottom: 0; } }

    .radio-buttons .radio-group .radio-button {
        width: calc(50% - 12px); }

    @media screen and (min-width: 960px) {
        padding-top: 85px;

        .fui-collapse-menu.is-dropdown {
            margin-top: 60px; }

        .fui-document-section {
            padding-top: 0; } } }

.fui-section-faq,
.fui-section-document {
    .side-menu {
        position: relative;
        z-index: 99;

        ~ * {
            z-index: 0; } } }

.fui-section-faq {
    padding: 24px 0;

    .fui-collapse-menu {
        margin-bottom: $space * 4.5; }

    @media screen and (min-width: 960px) {
        padding: 60px; } }

.help-center-contact {
    section.fui-content {
        > .fui-container > h3 {
            margin-bottom: 0;

            + h4 {
                margin-top: 0; } }

        .fui-panel {
            h4 {
                font-weight: $font-regular;
                font-size: 22px;
                color: $black50; } } } }

main.help-center {
    margin-top: 50px;

    .fui-panel {
        padding: 30px !important;

        h4 {
            margin-top: 0; }

        h3.fui-panel-title {
            margin-bottom: 24px; } }

    .fui-collapse.is-open .collapse-body {
        margin-top: 18px; }

    .blue-dot-list {
        padding-left: 30px;
        list-style: none;

        li {
            position: relative;
            line-height: 1.78;

            &:before {
                content: "•";
                color: $business50;
                font-size: 30px;
                position: absolute;
                left: -28px;
                top: -10px; } }

        &.with-circle {
            list-style-type: none;
            padding: 12px 0 0 0;

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 1;
                margin-bottom: 8px;
                padding-left: 0;

                &::before {
                    display: none; } }

            .circle {
                font-size: 14px;
                background-color: rgba(57, 102, 218, 0.2);
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $business50;
                font-weight: 400;
                position: relative; } } }

    .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link, .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited, .fui-panel a:not(.sub-link-title):not(.fui-button):link, .fui-panel a:not(.sub-link-title):not(.fui-button):visited {
        color: $business50; }

    .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):link::after, .fui-card .fui-card-content a:not(.sub-link-title):not(.fui-button):visited::after, .fui-panel a:not(.sub-link-title):not(.fui-button):link::after, .fui-panel a:not(.sub-link-title):not(.fui-button):visited::after {
        background: $business50 !important; }

    @media screen and (max-width: 960px) {
        .fui-panel {
            padding: 15px !important; } } }

.faq-container {
    .search-empty {
        width: 100%;

        img {
            width: 300px;
            display: block;
            margin: 0 auto ($space * 4); } }

    .highlight {
        color: $accent !important; }

    .fui-panel {
        padding: 15px 15px;

        h3 {
            color: $business50; }

        .panel-more-link {
            margin: 15px -15px -15px;
            background: $lightgray50;
            text-align: center;

            .fui-button {
                margin: 0;
                color: $business50; } }

        .faq-detail,
        .fui-collapse.is-faq {
            padding: 15px;
            background: $lightgray70;
            margin-bottom: $space * 1.5;

            .collapse-body {
                margin-bottom: 0; }

            .collapse-body > * {
                padding: 0 0 36px;

                article > p:first-child {
                    margin-top: 0; } }

            .collapse-header {
                font-weight: $font-medium;

                &::before {
                    color: $darkgray50; } }

            hr {
                border-color: $black50; } }

        .feedback {
            *:not(.form-group) {
                display: inline-block;
                vertical-align: middle;
                left: 0; }

            form .form-group {
                margin-bottom: 0;

                > label {
                    min-height: 0;
                    height: auto;
                    margin: 0; }

                .radio-buttons {
                    margin-top: $space * 1.5; }

                .max-length-counter,
                .radio-buttons .radio-group .radio-button,
                .max-length-counter {
                    width: 100%; } }

            form .input-group {
                width: 100%;

                button.fui-button {
                    width: calc(50% - 8px);
                    padding: 0 26px;

                    @media screen and (min-width: 768px) {
                        width: auto; } } }

            .text {
                margin-right: 20px; }

            .fui-button.is-secondary {
                min-width: 0;
                margin-bottom: 0;
                border-color: $black50;

                .text {
                    margin-right: 0; }

                &::after {
                    display: none; } }

            @media screen and (max-width: 374px) {
                .text {
                    margin-right: 4px; }

                .fui-button.is-secondary + .fui-button.is-secondary {
                    margin-right: 0; } } } }

    @media screen and (min-width: 960px) {
        .search-empty {
            img {
                float: right; }

            .content {
                display: block;
                margin-right: 200px; } }

        .fui-panel {
            padding: 30px 30px;

            .panel-more-link {
                margin: 30px -30px -30px; }

            .faq-detail,
            .fui-collapse.is-faq {
                padding: 30px; } } } }

.is-help-center {
    .fui-panel {
        h4 {
            font-weight: $font-regular; } } }

section.fui-section-complete {
    text-align: center;

    img {
        width: 186px; }

    .fui-button {
        width: 100%; }

    @media screen and (min-width: 768px) {
        img {
            width: 220px; }

        .fui-button {
            width: auto; } } }

section.fui-help-shortcut {
    padding: ($space * 5.5) 0;
    background: $lightgray100;

    .fui-shortcut-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        a.fui-shortcut-item,
        a.fui-shortcut-item:link,
        a.fui-shortcut-item:visited {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3);
            transition: all 0.3s ease;
            text-align: center;
            padding: 18px 0 12px;

            &::after {
                display: none !important; }

            &:hover {
                box-shadow: 0 20px 48px 0 rgba(75, 88, 105, 0.24);
                background: $white; }

            img {
                width: 80px;
                height: 80px;
                margin: auto;
                align-self: flex-start; }

            .text {
                flex: 1 1 auto;
                flex-grow: 1;
                color: $black50;
                margin-top: $space * 1.5;
                font-weight: $font-medium; } } }

    @media screen and (min-width: 960px) {
        padding: 60px 0;

        .fui-shortcut-container {
            flex-wrap: nowrap;

            .fui-shortcut-item {
                width: auto;
                flex: 1 1 auto;

                img {
                    width: 80px;
                    height: 80px; }

                .text {
                    font-size: 20px; } } } } }

section.fui-app-promotion {
    color: #FFF;
    background-color: $darkgray50;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    &.app-promotion-2 {
        padding: 88px 0 82px;
        .body {
            margin: 0 0 6px;
            display: flex;
            align-items: center;
            font-weight: 500;
            img {
                width: 34px;
                margin-right: 6px; } } }
    &.app-promotion-3 {
        padding: 88px 0 82px;
        .app-info {
            display: flex;
            height: 100%;
            align-items: center;
            img {
                margin-right: 24px; }
            h4 {
                margin: 0; } } }

    .top-area {
        display: inline-block;

        img + div {
            display: block;
            margin-left: 82px; }

        h3 {
            display: block;
            vertical-align: top;
            margin-bottom: 6px; }

        h5 {
            display: block;
            vertical-align: top; } }

    h3, h5 {
        margin: 0; }

    p {
        margin-top: 24px; }

    .download img {
        margin: 0 auto 10px;
        width: 100%;
        max-width: 144px; }

    img.float-left.mr-2 {
        max-width: 96px; }

    a,
    a *,
    a:link *,
    a:visited * {
        color: $white;
        width: 100%;
        max-width: 144px;

        img {
            width: 100%; }

        &::after {
            display: none; } }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        background-size: 200% auto;

        p {
            margin-top: 0; } }

    @media screen and (min-width: 1200px) {
        background-position: center;

        p {
            margin-top: 0; }

        .top-area {
            h3 {
                width: 100%; }

            h5 {
                width: 100%; } } }
    @media screen and ( max-width: 960px ) {
        height: 500px;
        padding: 60px 0!important;
        .top-area {
            h3 {
                font-size: 28px; }
            h5 {
                font-size: 22px;
                .app-info {}
                img {
                    width: 80px;
                    height: 80px; } } }
        p {
            font-size: 16px!important;
            line-height: 1.75!important;
            margin-bottom: 24px; }
        &.app-promotion-2 {
            padding: 60px 0;
            .top-area {
                margin-bottom: 48px; } }
        &.app-promotion-3 {
            padding: 66px 0 30px;
            .top-area {
                margin-bottom: 60px;
                .d-block {
                    margin-left: 0px; } } } } }
