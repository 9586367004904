main.rate-plan {
    position: relative;
    .fui-banner.is-product.is-style-2 .banner-img > div {
        background-position: right; }

    .fui-banner.is-product .caption {
        padding-left: 0;
        .subtitle {
            display: block;
            overflow: visible; } }

    .fui-banner .fui-container {
        width: 90%; }
    .fui-banner.is-product.is-style-2 {
        min-height: initial;
        height: 240px;

        .caption {
            top: 16px; } }

    .radio-group .radio.is-icon-box .content {
        padding: 24px 12px 24px 46px;
        height: 100%;
        border-color: #e8ecf0; }

    .form-group {
        .radio-group .radio {
            width: calc(50% - 12px); } }

    .form-group:nth-of-type(4) {
        .radio {
            width: calc(25% - 12px); } }

    .result {
        .result-wrapper {
            display: flex;
            flex-direction: row;
            box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12);

            .left {
                flex-basis: 392px;
                background-image: url(#{$publicURL}/resources/cbu/e-service/images/result-bg.svg);
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: contain;
                background-color: #EFF4F1;
                padding: 60px 30px;

                h5 {
                    margin-top: 12px;
                    margin-bottom: 18px; } }

            .right {
                padding: 30px 60px;
                width: 100%;
                background-color: $white;

                h2 {
                    color: $error;
                    margin-bottom: 16px;

                    p {
                        margin-bottom: 75px;
                        margin-top: 0; }

                    a {
                        margin-bottom: 30px; }

                    h6 {
                        margin-top: 0;
                        margin-bottom: 30px; } } } } }

    .fui-panel-information {
        background-color: $gray30;
        margin-top: 30px;
        padding-top: 60px;
        padding-bottom: 60px;
        color: $darkgray50;

        .fui-collapse {
            width: 90%;
            margin: 0 auto;
            padding: 0 15px; }

        .collapse-header {
            max-width: 1080px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 30px; }

        .collapse-body {
            max-width: 1080px;
            margin: 0 auto;

            article {
                margin: 0;
                padding: 0;

                ol {
                    padding-left: 18px;
                    margin: 0;

                    li {
                        font-size: 14px;
                        line-height: 24px; } } } } } }

@media screen and (max-width: 960px) {
    main.rate-plan {
        .fui-banner.is-product.is-style-2 {
            .caption {
                top: -16px; } }
        .form-group:nth-of-type(4) {
            .radio {
                width: calc(50% - 12px); } }

        .result {
            .result-wrapper {
                flex-direction: column;

                .left {
                    padding: 30px 20px;
                    flex-basis: initial; }

                .right {
                    padding: 30px 20px; } } }

        .fui-panel-information {
            padding: 30px;

            .fui-collapse {
                padding: 0;
                width: 100%; } }

        // .radio-group .radio input + .content
        //     &::before
        //         top: 0

        //     &::after
 } }        //         top: 0
