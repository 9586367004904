section.card-product-multiple {
  .horizontal-cards.is-program {
    .slick-slide {
      .fui-card.is-card-program {
        img {
          height: 192px;
          margin: 12px 0; }
        .product-spec {
          width: 100%;
          margin-bottom: 18px;
          max-width: 226px;
          margin-top: 12px; }
        .fui-button {
          max-width: 226px;
          min-width: auto; } } } }
  .horizontal-cards.is-program .slick-slide .fui-card.is-card-program .d-inline {
    height: auto;
    overflow: visible; }
  @media screen and (max-width: 960px) {
    .slick-initialized .slick-slide {
      height: auto!important; }
    .horizontal-cards.is-program {
      .slick-slide {
        .fui-card.is-card-program {
          img {
            height: 120px; }
          .d-inline {
            min-width: 78px; } } } } } }
