.fui-dropzone > div .upload-preview {
    .check {
        z-index: 2; }
    .img-wrap {
        height: 120px;
        width: 90%;
        position: relative;
        margin: auto;
        img {
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; } } }

@media screen and ( max-width:768px  ) {
    .fui-dropzone > div .upload-preview {
        padding: 30px 0; } }
