section.section-product-card {
    h2 {
        margin-bottom: 36px; }

    .fui-card.is-product {
        display: flex;
        flex-direction: column;
        padding-top: 0;

        .fui-card-image {
            padding-bottom: 72%;

            img {
                position: absolute;
                width: auto;
                max-width: none;
                height: 100%;
                left: -100%;
                right: -100%;
                top: -100%;
                bottom: -100%;
                margin: auto; } }

        .fui-card-caption {
            .fui-button {
                min-width: 100%; }

            .fui-card-price {
                margin-bottom: 18px;
                margin-top: 12px;
                font-size: 16px;

                del {
                    margin: 0 12px 0 6px; }

                span {
                    font-weight: bold; } } }

        .prodhottag {}

        .tag {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            color: $white;
            font-size: 14px;
            padding: 4px 12px;

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-right: 28px solid transparent;
                position: absolute;
                right: -28px;
                bottom: 0; } }

        .tag-red {
            background-color: $coral100;

            &::after {
                border-top: 28px solid $coral100;
                top: 0px; } }

        .tag-blue {
            background-color: $blue50;

            &::after {
                border-top: 28px solid $blue50;
                top: 0px; } }

        .tag-gray {
            background-color: #eff4f1;
            color: $black50;

            &::after {
                border-top: 28px solid #eff4f1;
                top: 0px; } } }

    @media screen and (max-width: 960px) {
        .fui-card.is-product {
            .fui-card-caption {
                .fui-card-price {
                    margin-top: 0;

                    .d-inline-flex {
                        width: 100%; }

                    span {
                        margin-left: 6px; }

                    .body-1 {
                        font-size: 14px; } } }

            .body {
                line-height: 1.5 !important;
                font-size: 16px; } } } }

.section-roaming-product-card {
    padding: 12px 0 0 0;
    overflow: visible !important;

    .horizontal-cards {
        margin-top: 0;

        .slick-list {
            // overflow: hidden
            padding-bottom: 40px; }

        .fui-card {
            .fui-card-action {
                height: 100%; }

            .fui-card-caption {
                padding: 30px 20px; }

            .fui-card-title {
                margin-top: 0;
                margin-bottom: 18px; }

            .fui-card-content {
                padding: 0;
                position: relative;

                .list {
                    margin-bottom: 60px;

                    > div {
                        margin-bottom: 3px; }

                    img {
                        margin-right: 3px;
                        display: inline-block;
                        vertical-align: middle; } } }

            .fui-card-extra {
                padding: 0;
                position: absolute;
                bottom: 0;
                width: 100%;

                .fui-button {
                    margin-bottom: 0; } } } }

    .slick-slider {
        .slick-arrow {
            display: block !important;
            position: absolute;
            width: 40px;
            height: 40px;
            background: $white;
            border-radius: 20px;
            overflow: hidden;
            border: 0;
            z-index: 1;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
            color: transparent;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 1;
            transition: opacity 0.3s ease;

            &.slick-disabled {
                opacity: 0; }

            &::after {
                position: absolute;
                font-family: "icomoon" !important;
                speak: none;
                color: $black50;
                width: 28px;
                height: 28px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                font-size: 28px;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1; }

            &.slick-prev {
                // left: -20px
                // left: calc(-89% - 5px)
                right: 98.5%;

                &::after {
                    content: ""; } }

            &.slick-next {
                right: -20px;

                &::after {
                    content: ""; } } } }

    @media screen and (max-width: 960px) {
        padding-top: 12px !important; }

    @media screen and (max-width: 768px) {
        .slick-slider {
            .slick-arrow {
                &.slick-prev {
                    // left: calc(-74% - 5px)
                    right: 92%; }

                &.slick-next {
                    right: -10px; }

                &.slick-prev,
                &.slick-next {
                    display: none !important; } } } } }
