.fui-tab {
    display: flex;
    width: 100%;
    white-space: nowrap;
    position: relative;

    .active-bar {
        position: absolute;
        height: 6px;
        left: 0;
        width: 100px;
        background: $accent;
        display: block;
        z-index: 1;
        bottom: 0;
        transition: all 0.3s ease; }

    a.fui-tab-item,
    .fui-tab-item {
        position: relative;
        flex: 1;
        flex-item: 1;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 6px ($space * 1.5) ($space * 1.5);
        color: $black50;
        cursor: pointer;
        display: inline-block;

        // padding: 7px 12px
        transition: color 0.3s ease;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 6px;
            display: block;
            bottom: 0;
            left: 0;
            background: $gray50; }

        &:hover,
        &:focus {
            color: initial; }

        &.is-active {
            color: $accent; }

        small {
            position: relative;
            bottom: -10px; } }

    &.with-scroller {
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none; }

        &::after {
            display: none; }

        a.fui-tab-item,
        .fui-tab-item {
            float: left;
            display: inline-block;
            padding: $space 10px;
            line-height: 1;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 6px;
                display: block;
                bottom: 0;
                left: 0;
                background: $gray50; } } }

    &.with-title {
        small {
            bottom: 0; } }

    &.with-icon, &.with-title {
        border-bottom: 0;

        &::after {
            display: none; }

        .active-bar {
            height: 4px; }

        a.fui-tab-item,
        .fui-tab-item {
            min-height: 80px;
            background-image: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%);

            .icon {
                text-align: center;

                * {
                    margin: 0 auto;
                    line-height: 0; }

                .default {
                    display: block; }

                .focus {
                    display: none; } }

            &::before {
                display: none; }

            &:hover,
            &:focus,
            &.is-active {
                .icon {
                    .default {
                        display: none; }

                    .focus {
                        display: block; } } }

            &.is-active {
                background: #FFF; } } }

    &#addValue {
        small {
            font-size: 22px;
            margin-bottom: 10px;
            display: block; }

        .text {
            color: $gray100; }

        .is-active {
            .text {
                color: #ff9173; } }

        .fui-tab-item:hover {
            .text {
                color: #ff9173; } } } }

.fui-nav-tab,
.fui-nav-anchor {
    position: relative;
    top: 0;
    display: flex;
    width: 100%;
    height: 45px;
    background: $white;
    z-index: 9;
    align-content: center;
    align-items: center;

    // &.is-fixed + section:not(.fui-breadcrumb-container),
    // &.is-fixed + section.fui-breadcrumb-container + section:not(.fui-banner.is-find-product),
    // &.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard)
    //     padding-top: 45px

    //     // margin-bottom: 45px
    //     margin-bottom: 0

    &.is-fixed + section.fui-breadcrumb-container + section:not(.fcoin-dashboard),
    &.is-fixed + section.fui-breadcrumb-container + section:not(.is-search-country) {
        margin-top: 100px; }

    &.is-fixed + section:not(.fui-breadcrumb-container).login-recommend,
    &.is-fixed + section.fui-breadcrumb-container + section.login-recommend {
        padding-top: 165px; }

    &.is-fixed + section.fui-breadcrumb-container {
        top: 0; }

    .fui-container {
        width: 100%;

        @media screen and (min-width: 960px) {
            width: 100%;
            max-width: 1440px; } }

    .page-anchor {
        .page-anchor-item {
            color: $black50;
            display: none; } }

    &.is-fixed {
        position: fixed;
        z-index: 100;

        .fui-button.is-primary + .fui-button.is-secondary.is-small {
            background: $white;
            color: $black50;
            border-color: $black50;
            transition: none;

            .text {
                transition: none; }

            &:hover {
                border-color: $accent;

                .text {
                    color: $accent; } } }

        .fui-button.is-secondary.is-small {
            display: block !important;
            opacity: 1;
            color: $white;
            background: $accent;
            border-color: $accent;

            &:hover {
                .text {
                    color: $white; } } }

        + .fui-tab-pane {
            margin-top: 45px; } }

    .fui-container {
        padding: 0 15px;
        display: flex;
        height: 45px;
        align-content: center;
        align-items: center; }

    .content {
        flex: 1 1 auto;
        margin-right: 80px; }

    .extra {
        position: absolute;
        display: flex;
        right: 15px;
        top: 0;
        bottom: 0;
        height: 25px;
        margin: auto;

        .fui-button.is-small.is-primary {
            border: 2px solid #D3060F; }

        .fui-button.is-small.is-secondary, .fui-button.is-small.is-primary {
            display: block;
            opacity: 1;
            font-size: 12px;
            height: 24px;
            line-height: 0;
            align-self: center;
            vertical-align: middle;
            margin: 0 0 0 $space;
            transition: all 0.3s 0.1s ease;

            .text {
                font-size: 12px;
                height: 20px;
                line-height: 20px; } } }

    .subtitle {
        font-size: 1.125rem;
        line-height: 27px;
        font-weight: $font-bold;

        img {
            vertical-align: middle; } }

    .fui-tab-container {
        position: relative; }

    &.is-fixed .extra .fui-button.is-small.is-secondary {
        display: block;
        opacity: 1; } }

.fui-nav-tab {
    padding: 0;
    height: 60px;

    .fui-container {
        height: 60px; }

    .subtitle {
        font-size: 0.875rem;
        font-weight: $font-regular; }

    .extra {
        .fui-button.is-small.is-secondary {
            display: block;
            opacity: 1; } }

    .fui-tab-container.mobile-dropdown {
        button.display {
            display: flex;
            font-weight: $font-medium;
            font-size: 1rem;

            > span {
                text-align: left;
                flex: 1 1 auto; }

            i {
                font-size: 24px; } }

        .fui-tab-item {
            padding-left: 15px;
            padding-right: 15px; } }

    &.is-content-1 {
        .content {
            margin: 0; }

        .extra,
        .subtitle {
            display: none; }

        .fui-tab-container.mobile-dropdown {
            button.display {
                width: 100%; } } }

    &.is-box-tab {
        width: 100%;
        max-width: 1189px;
        margin: 0 auto;
        padding: 0;

        @media screen and (max-width: 767px) {
            &.is-full-width,
            &.is-full-width.with-scrollbar {
                .fui-tab {
                    display: flex;

                    .fui-tab-item {
                        width: auto;
                        flex: 1;
                        flex-grow: 1; } } } }

        .fui-tab {
            width: 100%;
            box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.12)"};
            background-image: linear-gradient(to bottom, $white, #f5f5f5);
            margin-bottom: 0;

            .active-bar {
                display: none; }

            .fui-tab-item {
                width: calc(100% / 3);
                min-height: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                &::before {
                    display: none; }

                small {
                    color: $gray100;
                    padding-top: 0;
                    bottom: 0;
                    transition: all 0.3s ease; }

                .text {
                    font-size: 1.125rem;
                    margin-top: $space * 0.5;
                    line-height: 1.2;
                    padding: 0 ($space / 2);
                    white-space: normal; } }

            .fui-tab-item:hover,
            .fui-tab-item.is-active {
                z-index: 1;
                box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.12)"};

                small {
                    color: $accent; } } } }

    &.is-fixed {
        .extra {
            display: flex; }

        .subtitle {
            display: block; }

        .content {
            margin-right: 100px; }

        .fui-tab-container.mobile-dropdown {
            button.display {
                width: auto;
                font-size: 1rem; } } } }

.fui-nav-anchor {
    padding: 12px 0;

    + .fui-banner.is-product {
        margin-top: 0; }

    .extra {
        top: 50%;
        margin-top: -12px; } }

.fui-section-tab {
    background: #FFF;
    box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.12)"};

    .fui-tab {
        .active-bar {
            display: none; }

        .fui-tab-item {
            background: $lightgray100;
            border-bottom: 0;
            height: 64px;
            display: flex;
            color: $black50;
            align-content: center;
            align-items: center;
            justify-content: center;
            padding: 0 3px;
            box-shadow: 2px 0 0 0 $lightgray100;

            &::before {
                display: none; }

            &:last-child {
                box-shadow: none; }

            .text {
                white-space: normal;
                font-size: 0.875rem;
                line-height: 1.5rem;
                vertical-align: middle; }

            &.is-active {
                color: $black50;
                background: $white; } } }

    .fui-tab-body {
        .fui-tab-pane {
            padding: 30px; } } }

@keyframes fadeIn {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

.fui-tab-pane {
    display: none;
    opacity: 0;
    width: 100%;

    &.is-active {
        position: relative;
        display: block;
        opacity: 0;
        z-index: 12;
        animation: fadeIn 1s ease forwards;

        .fui-dropdown.for-banner {
            width: 72%;

            // margin-bottom: 20px
            .fui-dropdown-item {
                width: 100%;
                max-width: 100%;
                font-size: 30px;

                span {
                    flex: 1; } } } } }

.fui-tab-container {
    .fui-tab-body {
        width: 100%; }

    .fui-tab-pane {
        padding-top: $space * 2; }

    &.mobile-dropdown {
        box-shadow: none !important;
        position: initial;

        button.display {
            font-size: 14px;
            padding: 0;
            background: none;
            border: 0;
            font-weight: $font-medium;

            span {
                margin-right: $space; } }

        .fui-tab {
            flex-direction: column;

            .fui-tab-item,
            .fui-tab-item.is-active {
                border-bottom: 1px solid $lightgray100;

                &::before {
                    display: none; } } }

        .fui-menu {
            position: absolute;
            top: 100%;
            left: 0px;
            width: 100%;
            background: #FFF;
            overflow: hidden;
            transition: height 0.3s ease;

            .fui-tab {
                .active-bar {
                    display: none; }

                .fui-tab-item {
                    display: flex;
                    padding-left: 15px;
                    padding-right: 15px;
                    text-align: left;
                    min-height: 72px;
                    align-content: center;
                    align-items: center;

                    &.is-active {
                        color: $black50;

                        &::after {
                            content: "";
                            position: absolute;
                            right: 15px;
                            top: -5px;
                            bottom: 0;
                            margin: auto;
                            width: 12px;
                            height: 7px;
                            border-left: solid 3px $main;
                            border-bottom: solid 3px $main;
                            transform: rotate(-45deg);
                            background-color: none !important; } } } } } } }

@media screen and (min-width: 768px) {
    .fui-tabl {
        .active-bar {
 }            // display: none

        a.fui-tab-item,
        .fui-tab-item {
            font-size: 1rem;
            padding-top: 12px; } }

    .fui-tab.with-title {
        small {
            display: block;
            margin-bottom: 3px;
            color: $gray100; }

        .title {
            font-size: 18px; }

        .fui-tab-item {
            min-height: 75px; }

        .is-active {
            small {
                color: $accent; } } }

    .fui-tab-container .fui-tab-body {
        .fui-dropdown.for-banner {
            // width: 85%
            .fui-dropdown-item {
                width: 100%; } } } }

@media screen and (min-width: 960px) {
    .fui-tab {
        position: relative;
        display: flex;

        a.fui-tab-item,
        .fui-tab-item {
            &:hover {
                color: $accent;

                small {
                    color: $accent; } } }

        &.with-icon {
            .active-bar {
                display: none; } } }

    .fui-section-tab {
        .fui-tab .fui-tab-item {
            height: 60px;

            .text {
                font-size: 1rem; } }

        .fui-tab-body .fui-tab-pane {
            padding: 60px;
            font-size: 1rem;
            line-height: 28px; } }

    .fui-tab-container {
        &.mobile-dropdown {
            button.display {
                display: none; }

            .fui-menu {
                position: relative;
                top: 0;
                height: 45px !important;
                overflow: visible;
                display: block;
                width: 100%;
                text-align: center;

                .fui-tab {
                    width: auto;
                    display: inline-block;
                    text-align: center;

                    .active-bar {
                        display: block;
                        bottom: 0px; }

                    .fui-tab-item {
                        display: inline-block;
                        min-height: 45px;
                        border-bottom: 0;
                        font-size: 0.875rem;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: $space * 4;
                        padding-right: $space * 4;

                        .text {
                            line-height: 45px; }

                        &.is-active {
                            color: $accent;

                            &::after {
                                display: none; } } } } } } }

    .fui-nav-anchor {
        position: relative;
        padding: 0;
        height: 45px;
        background: $white;
        box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.12)"};

        // + .fui-banner
        //     margin-top: 45px !important

        .fui-container {
            height: 45px; }

        .extra {
            // right: 60px
            display: block; }

        .content {
            .subtitle {
                position: relative;
                display: inline-flex;
                z-index: 3; }

            .page-anchor {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 45px;
                text-align: center;

                .page-anchor-item,
                a.page-anchor-item:link,
                a.page-anchor-item:visited {
                    cursor: pointer;
                    display: inline-block;
                    color: $black50;
                    height: 45px;
                    line-height: 45px;
                    padding: 0 ($space * 4);
                    font-size: 14px;

                    &.active,
                    &.is-active {
                        color: $accent;

 } } } }                        // border-bottom: 6px solid $accent

        .extra {
            .fui-button.is-small {
                height: 30px;
                line-height: 22px;
                min-width: 0;
                border-color: $black50; } } }

    .fui-nav-tab {
        position: relative;
        top: 0px;
        height: 45px;
        background: $white;
        box-shadow: 0 24px 48px 0 #{"rgba(75, 88, 105, 0.12)"};

        &.is-box-tab {
            padding: 0;
            height: auto;
            background: none;
            margin-top: -37.5px;

            .fui-tab .fui-tab-item {
                small {
                    font-weight: 400; }

                .text {
                    font-weight: $font-medium;
                    font-size: 1.25rem; } } }

        .fui-tab-container.mobile-dropdown {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            display: inline-block;
            width: auto;

            button.display {
                display: none; } }

        .subtitle {
            position: absolute;
            left: 0;
            z-index: 9;
            font-size: 20px;
            height: 25px;
            font-weight: $font-bold;
            top: 0;
            bottom: 0;
            margin: auto; }

        .fui-container {
            height: 45px; }

        .content {
            position: relative;
            height: 45px;
            flex: 1 0 auto;
            margin-right: 0 !important; }

        .extra {
            // right: 60px
            .fui-button.is-small {
                height: 30px;
                line-height: 26px;
                min-width: 0;
                border-color: $black50; } }

        &.is-content-1 {
            .extra,
            .subtitle {
                display: none !important; }

            &.is-fixed {
                .extra,
                .subtitle {
                    display: block !important; } } } }

    .fui-tab-pane.is-active .fui-dropdown.for-banner .fui-dropdown-item {
        font-size: 36px; } }

@media screen and (max-width: 960px) {
    .fui-tab#addValue {
        small {
            font-size: 18px; }

        .text {
            font-size: 12px; } }

    .fui-tab#addValue + .fui-tab-pane {
        margin-bottom: 72px;

        .slick-slider {
            margin-top: 24px; } } }

@media screen and (max-width: 768px) {
    .fui-tab {
        a.fui-tab-item,
        .fui-tab-item {
            padding: 6px 6px 12px;

            small {
                display: block;
                padding-top: 4px;
                margin-bottom: 4px;
                color: $gray100; }

            .title {
                font-size: 16px; }

            &.is-active {
                small {
                    color: $accent !important; } } }

        &.with-scroller {
            width: initial;
            margin-right: -15px; } }

    .tab-content {
        display: flex;
        flex-direction: column;

        .action {
            .fui-button {
                max-width: 180px; } } }

    .fui-banner .fui-tab-container .fui-tab-body .fui-tab-pane .tab-content h3.tab-title {
        line-height: 44px;

 }        // max-width: 230px

    .fui-card .fui-card-content .fui-card-title {
        .fui-card {
            .fui-card-content {
                .fui-card-title {
                    margin: 0; } } } } }
