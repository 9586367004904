.google-map {
    color: $darkgray50;
    position: relative;
    &::after {
        content: '';
        background-image: url(#{$publicURL}/resources/cbu/img/ebu-lightgray-pattern-1440x460.jpg);
        background-size: cover;
        width: 100%;
        height: 270px;
        top: 0;
        z-index: 0;
        position: absolute;
        background-position: top left; }
    .fui-container {
        padding-top: 60px;
        padding-bottom: 60px;
        height: 270px;
        position: relative;
        z-index: 1;

        h4 {
            margin: 0 0 24px 0; } }

    .info-list {
        display: flex;
        margin-bottom: 6px;

        p {
            margin: 0; }

        .is-text-medium {
            margin-right: 12px;
            min-width: 64px; } }

    .google-map-display {
        width: 100%;
        height: 400px;
        margin-bottom: 90px; }

    .map-label {}

    @media screen and (max-width: 960px) {
        &::after {
            height: 430px; }
        .fui-container {
            height: initial;

            h1 {
                min-width: 190px; } } } }
