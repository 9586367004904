.event-info {
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  // background-color: $white
  position: relative;
  .border-bottom {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 12px solid rgba(240, 92, 56, .4); } } }
