section.service-entry {
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 6px; }
  .fui-card {
    padding: 18px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
    width: 100%;
    justify-content: center;
    p {
      margin: 0;
      text-align: center; }
    small {
      text-align: center; } }
  a {
    height: 100%;
    display: flex; }
  @media screen and ( max-width: 960px ) {
    .fui-card {
      padding: 4px 6px;
      min-height: 70px;
      small {
        font-size: 12px; } } } }
