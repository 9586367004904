.panel-multi-step {
    .circle {
        position: absolute;
        top: 22px;
        left: 30px;
        background-color: $darkgreen;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 24px;
        font-weight: 500;
        line-height: 2.3; }

    figure.fui-figure {
        margin-bottom: 0;

        img {
            max-width: 100%; }

        figcaption {
            margin-bottom: 0; }

        @media screen and (min-width: 768px) {
            margin-top: $space * 8;

            img {
                width: auto;
                display: block;
                margin: auto; } } } }
