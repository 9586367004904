.carousel-banner-3 {
    height: 360px;
    background-color: $gray100;
    background-position: center;
    margin-bottom: 60px;

    .slick-dots {
        position: absolute;
        bottom: 30px;
        z-index: 1;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        left: 0;
        right: 0;
        text-align: center;

        .slick-active {
            background: $white;

            button {
                opacity: 0;
                background-color: #fff; } }

        li {
            position: relative;
            display: inline-block;
            margin-right: 6px;
            background-color: $gray100;
            height: 4px;
            width: 50px;

            button {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                left: 0;
                cursor: pointer; }

            &:nth-last-child(1) {
                margin-right: 0;

                button {
                    cursor: pointer;
                    width: 50px;
                    height: 4px;
                    border: 0;
                    vertical-align: top; } } } }

    .slide {
        position: relative; }

    .bg-img {
        position: absolute;
        background-position: center;
        background-size: auto 100%;
        width: 100%;
        height: 100%;
        filter: brightness(0.8);

        @media screen and (min-width: 1920px) {
            max-width: 1920px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto; } }

    .content-wrapper {
        width: 50%; }

    .content {
        height: 360px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
        padding-top: $space * 11;

        p {
            font-weight: 600; }

        .subtitle {
            margin-top: 0;
            margin-bottom: 0; }

        h1 {
            margin-top: 10px;
            margin-bottom: 22px;
            line-height: 3.2rem;

            > h1 {
                margin: 0 !important; } }

        h2 {
            margin-top: 10px;
            margin-bottom: 22px;
            line-height: 3.2rem; }

        a {
            border-color: #fff !important;
            margin-right: 0; } } }

@media screen and (max-width: 960px) {
    .carousel-banner-3 {
        height: 320px;
        margin-bottom: 0;

        .content {
            max-width: 70%;
            height: auto;
            padding-top: 0; }

        .slick-dots {
            position: relative;
            bottom: 32px; }

        .bg-img {
            height: 320px; }

        .slick-slide {
            height: 320px;

            > div,
            .slide {
                height: 320px; }

            .bg-img {
                position: absolute;
                top: 0;
                width: 100%;
                height: 320px; }

            .content-wrapper {
                // position: absolute
                left: 0;
                width: 100%;
                height: 320px; }

            .content {
                // padding-top: 60px
                padding-left: 0;
                display: block;

                p.subtitle {
                    margin: 0;
                    margin-top: 48px; }

                h1 {
                    margin-bottom: 12px;
                    line-height: 1.5;
                    font-size: 28px; }

                h2 {
                    margin-bottom: 12px;
                    line-height: 1.5;
                    font-size: 28px; }

                a {
                    min-width: 190px;
                    padding: 2px 16px; } } } } }
