section.section-cooperation {
  display: flex;
  background-color: #465a5e;
  background-image: url(#{$publicURL}/resources/common/images/cbu-promotion-watch-1920x92.jpg);
  background-position: center;
  background-size: cover;
  height: 92px;
  padding: 0;
  align-items: center;
  img {
    width: 96px; }
  @media screen and ( max-width: 768px ) {
    background-image: url(#{$publicURL}/resources/common/images/cbu-promotion-watch-750x720.jpg);
    display: flex;
    height: auto;
    padding: 30px 0;
    .justify-center {
      flex-direction: column;
      .flex-align-center {
        flex-direction: column;
        small {
          margin-bottom: 30px; } } }
    img {
      width: 142px;
      margin-bottom: 30px; } } }
