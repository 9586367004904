section.search-empty {
    img {
        display: block;
        line-height: 0;
        width: 180px;
        margin: 0 auto; }

    h4 {
        margin-top: 58px; }

    @media screen and (min-width: 960px) {
        img {
            float: right;
            width: 300px; } } }

.search-result-tabpane {
    position: relative;
    z-index: 0;
    overflow: hidden; }

.product-card-sections {
    position: relative;
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        max-width: 1440px;
        height: 120%;
        top: -90px;
        z-index: -1;
        pointer-events: none;
        background: url(#{$publicURL}/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y;
        background-size: 1440px auto; }

    @media screen and (min-width: 960px) {
        &::before {
            background: url(#{$publicURL}/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y; } } }

.result-container.is-product {
    background: url(#{$publicURL}/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y;
    background-size: 1440px auto;

    @media screen and (min-width: 960px) {
        &::before {
            background: url(#{$publicURL}/resources/cbu/search-result/bg-ribbon-sm@2x.png) center top repeat-y; } } }

.fui-cards.no-scrollbar.is-product-result,
.fui-product-cards {
    position: relative;
    z-index: 1;
    padding-bottom: 60px;

    .horizontal-cards.slick-slider .slick-list .slick-slide {
        display: block;

        > div {
            flex-grow: 1;
            height: 100%; } }

    .more {
        margin-top: $space * 7; }

    .fui-card.is-product-carousel {
        position: relative;
        max-width: 270px;

        .fui-card-image {
            height: auto;
            padding: 30px 50px 0;

            img {
                position: relative;
                max-width: 100%;
                height: auto;
                top: 0;
                bottom: auto;
                left: 0;
                right: auto; } }

        &.is-product {
            .fui-card-image img {
                width: 140px;
                height: auto; } }

        &.is-moive {
            .fui-card-image img {
                width: 190px;
                height: auto; }

            h4.fui-card-title {
                white-space: nowrap;
                -webkit-line-clamp: 1; } }

        &.is-ring {
            .fui-card-image img {
                width: 150px;
                height: auto; }

            h4.fui-card-title {
                white-space: nowrap;
                -webkit-line-clamp: 1; } }

        &.is-music {
            .fui-card-image img {
                width: 190px;
                height: auto; }

            h4.fui-card-title {
                white-space: nowrap;
                -webkit-line-clamp: 1; } }

        .fui-card-action {
            display: flex;
            height: 100%; }

        .fui-card-caption {
            flow: 1;
            flex-grow: 1;

            h4.fui-card-title .text {
                font-size: 16px;
                font-weight: $font-regular;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis; } }

        .fui-card-extra {
            display: flex;
            font-weight: $font-medium;
            margin-top: $space * 2;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center;

            a {
                flex: 1;
                flex-grow: 1;
                text-align: center;

                .icon img {
                    margin: auto; } } }

        .fui-card-ribbon {
            position: absolute;
            left: 0;
            top: 0;
            color: $white;
            background: $coral100;
            font-size: 0.875rem;
            height: 28px;
            line-height: 28px;
            padding: 0 12px;
            z-index: 9;

            &::after {
                content: "";
                float: right;
                display: block;
                margin-right: -40px;
                width: 0px;
                height: 0px;
                border-left: 14px solid $coral100;
                border-top: 14px solid $coral100;
                border-right: 14px solid transparent;
                border-bottom: 14px solid transparent; } }

        @media screen and (min-width: 960px) {
            max-width: 100%;

            .fui-card-caption {
                h4.fui-card-title .text {
                    font-size: 20px; } } } } }

.fui-cards.no-scrollbar.is-product-result {
    margin: ($space * 3) -6px;
    padding: 0;

    .fui-card.is-product-carousel {
        width: calc(50% - 12px);

        .fui-card-image {
            text-align: center;
            padding: ($space * 4.5) ($space * 5) 0;

            img {
                margin: 0 auto; } }

        .fui-card-ribbon {
            font-size: 0.875rem; }

        .fui-card-caption {
            .fui-card-content {
                h4.fui-card-title .text {
                    font-size: 1rem; } } } }

    @media screen and (min-width: 768px) {
        .fui-card.is-product-carousel {
            width: calc(25% - 12px); } } }

section.result-container {
    padding: 30px 0 64px;

    .related-keyword {
        margin-top: $space * 3;

        .fui-button.is-tag {
            padding: 0 ($space * 1.5);
            margin-bottom: $space * 1.5;
            font-size: 0.875rem;
            height: 30px;
            line-height: 26px;

            @media screen and (min-width: 960px) {
                padding-left: $space * 2;
                padding-right: $space * 2;
                font-size: 1rem;
                height: 40px;
                line-height: 36px; } } }

    .result-list {
        position: relative;
        margin: $space 0;
        z-index: 2;

        .result-item {
            padding: ($space * 2) 0;
            border-bottom: solid 1px $lightgray100;

            &:last-child {
                border: 0; }

            .result-content,
            a.result-content:link,
            a.result-content:visited {
                display: inline-block;

                &:hover .result-header > span,
                &:hover .result-header > span .highlight {
                    color: $business50;
                    line-height: 0;
                    vertical-align: baseline; }

                .highlight {
                    color: $accent;
                    line-height: 0;
                    vertical-align: baseline !important; }

                .result-header {
                    color: $black50;
                    margin: 0 0 6px;

                    // *
                    //   vertical-align: middle
                    i[class*='icon-'] {
                        width: 30px;
                        font-size: 30px;
                        margin-right: 6px;
                        vertical-align: middle; } }

                .result-description {
                    margin: 0;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    color: $darkgray50;
                    display: block;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical; } } } }

    .result-extra {
        margin: ($space * 3) 0 0;

        .fui-cards {
            height: auto;
            margin: 0 - $space * 2.5;
            padding: ($space * 7) ($space * 2);

            .fui-card {
                min-width: 270px;

                a.fui-card-action,
                .fui-card-action {
                    min-height: 222px; }

                .fui-card-content {
                    min-height: 178px;

                    h4.fui-card-title {
                        margin-bottom: 12px;

                        .text {
                            font-size: 18px;
                            line-height: 27px;
                            -webkit-line-clamp: unset; } }

                    .fui-card-description {
                        margin-top: 6px;
                        margin-bottom: 6px;
                        font-size: 14px;
                        line-height: 1.5;
                        height: 60px;
                        -webkit-line-clamp: unset; } } } } }

    @media screen and (max-width: 960px) {
        // .result-list
        .result-extra {
            .fui-card {
                max-width: 270px;

                .fui-card-description {
                    -webkit-line-clamp: 2; } } } }

    @media screen and (min-width: 960px) {
        .result-list {
            .result-content .result-header {
                i[class*='icon-'] {
                    display: none !important; } } } } }

.is-eservice,
.is-cbu {
    section.result-container .result-list .result-item {
        .result-content,
        a.result-content:link,
        a.result-content:visited {
            &:hover .result-header > span,
            &:hover .result-header > span .highlight {
                color: $accent; } } } }
