.fui-ruler {
    background-color: #f1f3f5;
    width: 100%;
    // margin-bottom: 6px
    overflow: hidden;

    &.main {
        .percentage {
            height: 48px;
            background-image: linear-gradient(to left, #fc7b66 0%, #ffa188);

            span {
                font-size: 22px;
                margin-top: 10px;

                &::after {
                    border-color: transparent transparent transparent #fc7a66;
                    border-width: 48px 0 0 48px;
                    top: -9px;
                    right: -48px; } } } }

    .percentage {
        height: 30px;
        background-image: linear-gradient(to right, #9edfe9, #5cc3d2 97%);
        position: relative;

        span {
            position: absolute;
            right: 0;
            font-size: 18px;
            color: #fff;
            font-weight: 500;
            margin-top: 2px;
            div {
                z-index: 1;
                position: relative;
                margin-right: 12px; }

            &.zero {
                left: 10px;
                color: #5f6e84;
                div {
                    margin-right: -5px; }

                &::after {
                    display: none; } }

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent transparent transparent #5cc3d2;
                position: absolute;
                border-width: 30px 0 0 30px;
                top: -2px;
                right: -30px; } } } }
@media screen and (max-width: 960px) {
    .fui-ruler {
        &.main {
            .percentage {
                height: 30px;
                span {
                    font-size: 16px;
                    margin-top: 4px; } } }
        .percentage {
            height: 20px;
            span {
                margin-top: 0;
                font-size: 14px; } } } }
