section.section-feature-icon {
  padding: 60px 0 20px;
  min-height: 316px;
  h2 {
    margin-bottom: 74px; }
  .icon-wrapper {
    justify-content: center;
    display: flex;
    img {
      object-fit: contain; } }
  @media screen and (max-width: 960px) {
    padding: 42px 0 0;
    min-height: 336px;
    h2 {
      margin-bottom: 36px; }
    .icon-wrapper {
      width: 282px;
      margin: 0 auto;
      display: flex;
      justify-content: end;
      img {
        max-width: 120px; } } } }
