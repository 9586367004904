.fui-tag {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: $white;
  font-size: 14px;
  padding: 4px 12px;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-right: 28px solid transparent;
    position: absolute;
    right: -28px;
    bottom: 0; }

  &.fui-tag-red {
    background-color: $coral100;

    &::after {
      border-top: 28px solid $coral100;
      top: 0px; } }

  &.fui-tag-blue {
    background-color: $blue50;

    &::after {
      border-top: 28px solid $blue50;
      top: 0px; } }

  &.fui-tag-gray {
    background-color: #eff4f1;
    color: $black50;

    &::after {
      border-top: 28px solid #eff4f1;
      top: 0px; } } }
